import {ItemContainer} from "@shared";
import "./OverviewTopItem.scss";

type Props = {
  title: string;
  value: string | number;
  isActive?: boolean;
  onClick?: () => void;
};

export function OverviewTopItem({title, value, isActive, onClick}: Props) {
  return (
    <ItemContainer
      onClick={onClick}
      isActive={isActive}
      className={`overview-top-item ${isActive && "overview-top-item--active"}`}
    >
      <div className={`title ${isActive && "title--active"}`}>{title}</div>
      <div className={`value ${isActive && "value--active"}`}>{value}</div>
    </ItemContainer>
  );
}
