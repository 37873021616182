import {ReactNode, useState} from "react";

export const useModal = () => {
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);

  const [allModalContent, setAllModalContent] = useState<ReactNode[]>([]);

  const modalContent = allModalContent.slice(-1)[0];

  const openModal = (content: JSX.Element) => {
    setModalVisibility(true);
    if (content) {
      setAllModalContent((prev) => {
        if (prev) {
          return [...prev, content];
        }
        return [content];
      });
    }
  };

  const closeModal = () => {
    setModalVisibility(false);
    if (modalContent) {
    }

    setAllModalContent((prev) => {
      if (prev.length > 0) {
        return prev.slice(0, -1);
      }
      return prev;
    });
  };

  return {isModalVisible, modalContent, openModal, closeModal};
};
