import "./ItemContainer.scss";
import {ItemContainerHeader} from "./ItemContainerHeader/ItemContainerHeader";

type Props = {
  children: React.ReactNode;
  className?: string;
  isActive?: boolean;
  onClick?: () => void;
};

export const ItemContainer = ({
                                children,
                                className,
                                isActive,
                                onClick,
                              }: Props) => {
  return (
    <div
      onClick={onClick}
      className={`item-container ${
        isActive && "item-container--active"
      } ${className}`}
    >
      {children}
    </div>
  );
};

ItemContainer.Header = ItemContainerHeader;
