import {ReactNode} from "react";
import "./ModalHeader.scss";

type Props = {
  text: string;
  children?: ReactNode;
  className?: string;
};

export function ModalHeader({text, children, className}: Props) {
  return (
    <div className={`modal-header ${className}`}>
      <span>{text}</span>
      {children}
    </div>
  );
}
