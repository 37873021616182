import {ReactNode} from "react";
import "./ModalFooter.scss";

type Props = {
  children: ReactNode;
  isLoading?: boolean;
};

export function ModalFooter({children}: Props) {
  return <div className="modal-footer">{children}</div>;
}
