import {Form, Modal} from "@/shared";
import "./SelectAddAthleteModal.scss";
import {useContext, useRef} from "react";
import {ModalContext} from "@/contexts";

type Props = {
  openAthleteCreateModal: () => void;
  openAthleteSelectModal: () => void;
};

export function SelectAddAthleteModal({openAthleteSelectModal, openAthleteCreateModal}: Props) {
  const ref = useRef(null);
  const {closeModal} = useContext(ModalContext);

  return (
    <Modal innerRef={ref} className='select-add-athlete'>
      <Modal.Header text="Add Athlete"/>
      <Form.Button onClick={openAthleteCreateModal}>
        CREATE NEW ATHLETE
      </Form.Button>
      <Form.Button type="outline" onClick={openAthleteSelectModal}>
        ADD ATHLETE
      </Form.Button>
      <Form.Button type="cancel" className='select-add-athlete__close-btn' onClick={closeModal}>
        CLOSE
      </Form.Button>
    </Modal>
  );
}
