import {IAthlete} from "@/interfaces/interface";
import {ImageFromAssets} from "@/shared";
import "./AthleteTableItem.scss";
import {useContext} from "react";
import {ModalContext} from "@/contexts";
import {EditAthleteModal} from "@/components/Modals/EditAthleteModal/EditAthleteModal";
import {AthleteAssessmentsModal} from "@/components";

type Props = {
  athlete: IAthlete;
  teamId: string;
  teamPin: string;
};


export function AthleteTableItem({athlete, teamId, teamPin}: Props) {
  const {openModal} = useContext(ModalContext);
  const {userData, pin, isPinUser} = athlete || {};
  const {email, name} = userData || {};
  const handleOpenEditAthleteModal = () => {
    openModal(<EditAthleteModal athlete={userData} teamId={teamId}/>);
  };

  const handleOpenAthleteAssessments = (event: any) => {
    event.stopPropagation();
    openModal(
      <AthleteAssessmentsModal
        athleteName={userData.name}
        athletePIN={pin}
        orgPIN={teamPin}
      />
    );
  };

  return (
    <div className={isPinUser ? "athlete-table-item" : "athlete-table-item athlete-table-item--disabled"}
         onClick={handleOpenEditAthleteModal}>
      <div className="name">{name}</div>
      <div className="pin">{email}</div>
      <div className="pin">Pin: {pin}</div>
      <div className="actions">
        <ImageFromAssets
          onClick={handleOpenAthleteAssessments}
          name="actions-assessments.svg"
        />
      </div>
    </div>
  );
}
