import React, {useContext} from "react";
import {Coach} from "@/interfaces/interface";
import {Form, Modal} from "@/shared";
import {ModalContext,} from "@/contexts";
import "./MakeCoachAnOrgAdmin.scss";


interface Props {
  coach: Coach;
  loading: boolean;
  handleCoachMakeOrgAdmin: () => void;
}

export function MakeCoachAnOrgAdmin({coach, loading, handleCoachMakeOrgAdmin}: Props) {
  const {closeModal} = useContext(ModalContext);

  return (
    <Modal className="edit-athlete-modal">
      <Modal.Header className='make-coach-admin-header' text={`Are you sure you want to make coach ${coach.name} an Org Admin?`}/>
      <Modal.Footer>
        <Form.Button type='cancel' onClick={closeModal}>
          CANCEL
        </Form.Button>
        <Form.Button loading={loading} onClick={handleCoachMakeOrgAdmin}>
          Yes
        </Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
