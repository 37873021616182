import React from "react";

type Props = {
  name: string;
  className?: string;
  onClick?: (event: any) => void;
};

export const ImageFromAssets = ({name, className, onClick}: Props) => {
  return (
    <img
      onClick={onClick}
      alt={name}
      className={className}
      src={require(`../../assets/images/${name}`)}
    />
  );
};
