import React, {FC, useContext, useEffect, useState} from "react";
import './Subscriptions.scss'
import {SuperadminContext} from "@/contexts";
import {SubscriptionRequestsTable, SubscriptionsTable} from "@/components";
import {ImageFromAssets} from "@/shared";
import {PlanEnum, PlanMappingEnum} from "@/interfaces/subscriptions-interfaces";

interface Props {

}

export const selectOptions = Object.keys(PlanEnum).map(key => ({
  text: PlanMappingEnum[key as keyof typeof PlanMappingEnum],
  value: PlanEnum[key as keyof typeof PlanEnum],
}));
console.log(selectOptions)

export const Subscriptions: FC<Props> = () => {
  const {subscriptionRequests, getSubscriptionsRequests, getSubscriptions} = useContext(SuperadminContext)
  const [currentTab, setCurrentTab] = useState<'overview' | 'new'>('overview')

  useEffect(() => {
    const init = async () => {
      await getSubscriptionsRequests()
    };
    init();
  }, []);

  const handleRefreshButton = () => {
    if (currentTab === 'overview') {
      getSubscriptions()
    } else {
      getSubscriptionsRequests()
    }
  }

  return (
    <div className='subscriptions-list'>
      <div className='subscriptions-list__tabs'>
        <div>
          <button
            onClick={() => setCurrentTab('overview')}
            className={`subscriptions-list__tabs__button ${currentTab === 'overview' && "subscriptions-list__tabs__button--active"}`}
          >
            Overview
          </button>
          <button
            onClick={() => setCurrentTab('new')}
            className={`subscriptions-list__tabs__button ${currentTab === 'new' && "subscriptions-list__tabs__button--active"}`}
          >
            New requests ({subscriptionRequests.length})
          </button>
        </div>
        <button
          onClick={() => handleRefreshButton()}
          className={`subscriptions-list__tabs__button`}
        >
          <ImageFromAssets name='rotate-right.svg'/>
        </button>
      </div>
      {currentTab === 'overview' &&
          <SubscriptionsTable/>
      }
      {currentTab === 'new' &&
          <SubscriptionRequestsTable/>
      }
    </div>
  )
}