import {AthleteAssessmentsModal} from "@/components/Modals/AthleteAssessmentsModal/AthleteAssessmentsModal";
import {ModalContext} from "@/contexts";
import {IAthlete} from "@/interfaces/interface";
import {ImageFromAssets} from "@/shared";
import {useContext} from "react";
import "./CoachAthleteTableItem.scss";
import {EditAthleteModal} from "@/components/Modals/EditAthleteModal/EditAthleteModal";

type Props = {
  athlete: IAthlete;
  teamId: string;
  teamPin: string;
};

export function CoachAthleteTableItem({athlete, teamId, teamPin}: Props) {
  const {openModal} = useContext(ModalContext);
  const {pin, username, userData, isPinUser} = athlete || {};
  const handleOpenAthleteAssessments = (event: any) => {
    event.stopPropagation();
    openModal(
      <AthleteAssessmentsModal
        athleteName={userData.name}
        athletePIN={pin}
        orgPIN={teamPin}
      />
    );
  };

  const handleOpenEditAthleteModal = () => {
    openModal(<EditAthleteModal athlete={athlete.userData} teamId={teamId}/>);
  };


  return (
    <div
      className={isPinUser ? "coach-athlete-table-item" : 'coach-athlete-table-item coach-athlete-table-item--disabled'}
      onClick={handleOpenEditAthleteModal}
    >
      <div className="name">{userData?.name}</div>
      <div className="name">{userData?.email}</div>
      <div className="pin">Pin: {pin}</div>
      <div className="actions">
        <ImageFromAssets
          onClick={handleOpenAthleteAssessments}
          name="actions-assessments.svg"
        />
      </div>
    </div>
  );
}
