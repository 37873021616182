import {baseURL} from "@/environments";
import {Auth} from "aws-amplify";
import axios from "axios";
import {toast} from "react-toastify";

export let accessToken = "";

const errorHandler = (error: any) => {
  console.log(error, "err");
  if (error.response.status === 403) {
    toast.error(`${error.response.data.message}`);
  }

  return Promise.reject({...error});
};

const Api = axios.create({
  baseURL: baseURL,
  timeout: 60000,
});

export const UnauthorizedApi = axios.create({
  baseURL: baseURL,
  timeout: 20000,
});

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => errorHandler(error)
);

Api.interceptors.request.use(
  async (config) => {
    //TODO add type to session
    const session: any = await Auth.currentSession();

    const jwtToken = session?.accessToken?.jwtToken;
    accessToken = jwtToken;

    if (config.headers) {
      config.headers.Authorization = jwtToken ? `Bearer ${jwtToken}` : "";
    }
    return config;
  }
  // (error) => toast.error("Oops, something went wrong", error)
);

export function SetAuthorizationToken(token: string | undefined) {
  if (token) {
    Api.defaults.headers.common["Authorization"] = token;
  } else {
    delete Api.defaults.headers.common["Authorization"];
  }
}

export default Api;
