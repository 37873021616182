import {OverviewEditItems} from "../../OverviewEditItems/OverviewEditItems";
import {OverviewTableItem} from "../OverviewTableItem";
import "./OverviewTableItemTeams.scss";

type Props = {
  name: string;
  persons: number;
};

const availableImages = [
  4511, 5988, 4574, 5883, 3517, 1028, 1636, 4886, 5055, 2187, 4709,
];

export function OverviewTableItemTeams({name, persons}: Props) {
  function generateRandomIndex() {
    return Math.floor(Math.random() * availableImages.length - 1) + 1;
  }

  return (
    <OverviewTableItem
      leftSide={<div className="overview-table-item-left-side">{name}</div>}
      rightSide={
        <div className="overview-table-item-right-side">
          <div className="right-side-initial">
            <div className="persons-images">
              {Array.from({length: persons})
                .slice(0, 4)
                .map((_, index) => {
                  return (
                    <img
                      key={index}
                      width={24}
                      height={24}
                      className="persons-images__image"
                      src={`https://faces-img.xcdn.link/thumb-lorem-face-${
                        availableImages[generateRandomIndex()]
                      }_thumb.jpg`}
                    />
                  );
                })}
            </div>
            <div className="persons__count">{persons}</div>
          </div>
          <div className="right-side-secondary">
            <OverviewEditItems/>
          </div>
        </div>
      }
    />
  );
}
