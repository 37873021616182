import './SubscriptionsTable.scss'
import {FC, useContext, useEffect} from "react";
import {ModalContext, SuperadminContext} from "@/contexts";
import {SubscriptionsTableItem} from "@/components/TableItems/SubscriptionsTableItem/SubscriptionsTableItem";
import {ISubscription, PlanEnum} from "@/interfaces/subscriptions-interfaces";
import Api from "@/api/api";
import {toast} from "react-toastify";
import {
  SuperadminSubscriptionInfoModal
} from "@/components/Modals/SuperadminSubscriptionInfoModal/SuperadminSubscriptionInfoModal";

interface Props {

}

export const SubscriptionsTable: FC<Props> = () => {
  const {openModal} = useContext(ModalContext);

  const {subscriptions, getSubscriptions, getSubscriptionsOverview} = useContext(SuperadminContext)
  const reversedSubscriptions = [...subscriptions].reverse();

  useEffect(() => {
    const init = async () => {
      await getSubscriptions()
    };
    init();
  }, []);

  const handleOrgPlanChange = async (orgId: string, subscriptionLevel: PlanEnum, expirationDate?: Date) => {
    const updateUrl = `/v2/admin/organizations/${orgId}/subscription/change`
    try {
      await Api.put(updateUrl, {subscriptionLevel, expirationDate: expirationDate});
      getSubscriptionsOverview();
      getSubscriptions();
    } catch (error: any) {
      const {message} = error?.response?.data;
      if (message) {
        toast.error(message);
      } else {
        toast.error("Something went wrong");
      }
    } finally {
    }
  }

  const handleTableItemClick = (item: ISubscription) => {
    openModal(<SuperadminSubscriptionInfoModal subscription={item} handleOrgPlanChange={handleOrgPlanChange}/>)
  }

  return (
    <div className='subscriptions-list__list'>
      <div className='subscriptions-list__list--header'>
        <div>Organizations</div>
        <div>ID</div>
        <div>Current payment plan</div>
        <div>Expired date</div>
        <div>Q-ty of teams</div>
        <div>Q-ty of coaches</div>
        <div>Q-ty of athletes (pins)</div>
      </div>
      {reversedSubscriptions.map(item =>
        <SubscriptionsTableItem
          onClick={() => handleTableItemClick(item)}
          subscription={item}
          key={`${item.id}${item.subscriptionInfo.subscriptionLevel}`}
          handleSubscriptionChange={handleOrgPlanChange}
        />)}
    </div>
  )
}