import {getDateInLocal, getSpeedInNeededUnits} from "@/helpers";
import {IAssessment} from "@/interfaces/interface";
import {useState} from "react";
import "./Assessment.scss";
import {Run} from "./Run/Run";

type Props = {
  assessment: IAssessment;
  assessmentTitle: string;
};

export function Assessment({assessment, assessmentTitle}: Props) {
  const [showRuns, setShowRuns] = useState(false);

  const {runs} = assessment;

  const getAverageAndTopSpeed = () => {
    const value = {
      average: 0,
      top: 0,
    };

    runs.forEach((run) => {
      value.average += +(run.topSpeed / runs.length);
    });

    value.top = Math.max(...runs.map((o) => o.topSpeed));

    return value;
  };

  const {average, top} = getAverageAndTopSpeed();

  const assessmentTime = getDateInLocal(runs[0]?.timestamp) || null;

  const handleOpenRunChart = () => {
  };

  return (
    <div className="assessment">
      <div
        onClick={() => {
          setShowRuns((prev) => !prev);
        }}
        className={`assessment__header ${
          showRuns && "assessment__header--active"
        }`}
      >
        <div className="assessment__header__title">{assessmentTitle}</div>
        <div className="assessment__header__date">{assessmentTime}</div>
      </div>

      {/* <RunChart runs={runs} /> */}

      {showRuns && (
        <div className="assessment__body">
          {runs.map((run, index) => {
            const {id, speedByTime} = run;

            //RUN IN MILES

            const runInMiles = {
              ...run,
              speedByTime: speedByTime.map((s) => ({
                time: s.time,
                speed: getSpeedInNeededUnits(s.speed, "yards"),
              })),
            };

            const runTitle = `Run ${index + 1}`;

            return (
              <Run
                assessmentID={assessment.id}
                runTitle={runTitle}
                key={id + index}
                run={runInMiles}
              />
            );
          })}

          <div className="assessment__body__statistic">
            <div className="assessment__body__statistic__item">
              <div>Average Top Speed</div>
              <div>{`${getSpeedInNeededUnits(average, "yards")} (MPH)`} </div>
              <div/>
            </div>

            <div className="assessment__body__statistic__item">
              <div>Max Top Speed</div>
              <div>{`${getSpeedInNeededUnits(top, "yards")} (MPH)`} </div>
              <div/>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
