import {ImageFromAssets} from "@/shared";
import "./Header.scss";
import {matchPath, useLocation, useNavigate} from "react-router-dom";
import {AuthContext} from "@/contexts";
import {useContext} from "react";
import {IRoute, UserRoleEnum} from "@/interfaces/interface";
import {RolePicker} from '@/components/RolePicker/RolePicker';
import {SubscriptionPicker} from "@/components/SubscriptionPicker/SubscriptionPicker";

interface Props {
  routes: IRoute[];
}

export const Header = ({routes}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const {signOut, userName, subscriptionInfo, userRole} = useContext(AuthContext);

  const currentRoute = routes.find((route) =>
    matchPath(location.pathname, route.to)
  );

  const currentRouteName = currentRoute?.title;

  return (
    <div className="header">
      <div className="header__left">{currentRouteName}</div>
      <div className="header__right">
        <div className="header__right__actions">
          <RolePicker/>
          {subscriptionInfo?.subscriptionLevel && userRole !==UserRoleEnum.ROLE_COACH && <SubscriptionPicker/>}
        </div>
        <div className="header__right__user">
          <div className="header__right__user__name">{userName}</div>
          <ImageFromAssets
            className="header__right__user__sign-icon"
            onClick={() => {
              signOut();
              navigate("/");
            }}
            name="header-signout.svg"
          />
        </div>
      </div>
    </div>
  );
};
