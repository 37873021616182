import React, {useContext, useState} from "react";
import {IAthleteUserData, IUser} from "@/interfaces/interface";
import {Form, Modal} from "@/shared";
import {AuthContext, CoachContext, CustomerContext, ModalContext} from "@/contexts";
import "./DeleteAthleteModal.scss";
import Api from "@/api/api";
import {toast} from "react-toastify";


interface Props {
  athlete: IAthleteUserData | IUser;
}

export function DeleteAthleteModal({athlete}: Props) {
  const [loading, setLoading] = useState(false);

  const {closeModal} = useContext(ModalContext);
  const {userRole} = useContext(AuthContext);
  const {getTeams} = useContext(CoachContext);
  const {getTeams: getCustomerTeams} = useContext(CustomerContext);

  const isCoach = userRole === "ROLE_COACH";

  const handleAthleteDelete = async () => {
    const deleteUrl = isCoach
      ? "/v2/coach/organizations/athletes/"
      : "/v2/organization-admin/organizations/athletes/";
    setLoading(true);
    try {
      await Api.delete(`${deleteUrl + athlete?.username}`);
      toast.success(
        "Athlete deleted successfully"
      );
      if (isCoach) {
        getTeams();
        closeModal();
      } else {
        getCustomerTeams();
        closeModal();
      }
      closeModal();
      closeModal();
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal className="delete-athlete-modal">
      <Modal.Header text={`Are you sure you want to remove athlete ${athlete?.name} from the records?`}/>
      <Modal.Footer>
        <Form.Button type="outline" loading={loading} onClick={closeModal}>
          CANCEL
        </Form.Button>
        <Form.Button type="cancel" onClick={handleAthleteDelete}>
          DELETE
        </Form.Button>

      </Modal.Footer>
    </Modal>
  );
}
