import "./RunChart.scss";
import {Point, PointSymbolProps, PointTooltipProps, ResponsiveLine,} from "@nivo/line";
import {useState} from "react";
import {ItemContainer} from "@/shared";
import {IRun} from "@/interfaces/interface";
import {getDistanceInNeededUnits, getSpeedInNeededUnits} from "@/helpers";

interface LineChartProps {
  runs: IRun[];

  onPointClick?(): void;
}

export const RunChart = ({
                           runs,
                           onPointClick,
                         }: LineChartProps): JSX.Element => {
  const handlePointClick = ({data}: Point) => {
    onPointClick && onPointClick();
  };

  const dataForChart = runs.map((run, id) => {
    return {
      id: id.toString(),
      data: run.speedByTime.map((d, index) => {
        let date = new Date(
          new Date(0).setMilliseconds(index === 0 ? 0 : d.time)
        ).toISOString();

        return {
          x: date,
          y: d.speed,
        };
      }),
    };
  });

  const renderTooltip = ({point}: PointTooltipProps): JSX.Element => {
    const {data} = point;

    const milliSeconds = new Date(data.xFormatted).getTime();
    const seconds = (milliSeconds / 1000).toFixed(1);

    return (
      <div className="point-tooltip">
        <div>{seconds} s</div>
        <div>{data.yFormatted} MPH</div>
      </div>
    );
  };

  type SelectedData = {
    x: string;
    xFormatted: string;
    y: number;
    yFormatted: string;
  };
  const [ID, setID] = useState<null | number>(null);
  const [selectedData, setSelectedData] = useState<SelectedData | null>(null);

  const renderPointSymbol = (props: PointSymbolProps) => {
    const {xFormatted, yFormatted} = props.datum;

    if (
      2 != 2
      // xFormatted === selectedData?.xFormatted &&
      // yFormatted === selectedData?.yFormatted
    ) {
      return (
        <>
          <circle
            className="point-symbol"
            cx="0"
            cy="0"
            r="2"
            fill={"white"}
            stroke={props.color}
            strokeWidth="3"
          />
          <circle
            className="point-symbol2"
            cx="0"
            cy="0"
            fill={"transparent"}
            stroke={props.color}
            strokeWidth="1"
          />
        </>
      );
    }
  };

  const chartColors = [
    "#10FF7B",
    "#00D1FF",
    "#1057FF",
    "#FF0FE3",
    "#FF7B0F",
    "#FFF30F",
  ];

  const defs = runs.map((run, index) => {
    return {
      id: index.toString(),
      type: "linearGradient",
      colors: [
        {offset: 0, color: chartColors[index]},
        {offset: 0, color: chartColors[index]},
      ],
      opacity: 0,
    };
  });

  const selectedRun = runs[0];
  const {distance, timeToTopSpeed, topSpeed, time} = selectedRun;

  const arrayForDetails = [
    {
      title: "Top Speed",
      value: getSpeedInNeededUnits(topSpeed, "yards"),
      units: "MPH",
    },
    {
      title: "Time to Top Speed",
      value: (timeToTopSpeed / 1000).toFixed(1),
      units: "s",
    },
    {
      title: "Distance",
      value: getDistanceInNeededUnits({
        distance: distance,
        distanceUnits: "yards",
      }),
      units: "yards",
    },
    {
      title: "Time",
      value: (time / 1000).toFixed(1),
      units: "s",
    },
  ];

  // const fills = runs.map((run) => ({
  //   match: (d) => {
  //     console.log(d, "dd");
  //     return d.id === "2";
  //   },
  //   id: "0",
  // }));

  return (
    <ItemContainer className="run-chart">
      <div className="run-chart__container">
        <ResponsiveLine
          xScale={{format: "%Y-%m-%dT%H:%M:%S.%L%Z", type: "time"}}
          xFormat="time:%Y-%m-%dT%H:%M:%S.%L%Z"
          axisBottom={{
            tickValues: "every 1 second",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: "%s",
            legend: "Time (s)",
            legendOffset: 38,
            legendPosition: "middle",
          }}
          theme={{
            textColor: "white",
            grid: {line: {stroke: "#999999", strokeWidth: 0.2}},
          }}
          data={dataForChart}
          curve={"cardinal"}
          margin={{top: 20, right: 24, bottom: 44, left: 40}}
          lineWidth={2}
          pointSize={10}
          useMesh={true}
          enableGridX={false}
          pointSymbol={renderPointSymbol}
          // onMouseMove={(d: any) => {
          //   if (d?.data) {
          //     setSelectedData(d.data);
          //   }
          // }}
          onMouseLeave={(d) => setSelectedData(null)}
          colors={chartColors}
          enableArea={true}
          areaOpacity={0.1}
          tooltip={renderTooltip}
          enableCrosshair={true}
          axisRight={null}
          yScale={{
            type: "linear",
          }}
          pointLabelYOffset={2}
          axisLeft={{
            tickSize: 0,
            legendOffset: -34,
            legendPosition: "middle",
            legend: "Speed (MPH)",
          }}
          defs={defs}
          // fill={[{ match: "*", id: "0" }]}
          fill={[
            {
              match: (d) => {
                return d.id === "2";
              },
              id: "0",
            },
            // { match: "*", id: "" },
          ]}
          onClick={handlePointClick}
        />
      </div>
      <div className="run-chart__details">
        {arrayForDetails.map(({title, units, value}) => (
          <div key={title} className="run-chart__details__item">
            <span>{title}</span>
            <span>{value + " " + units}</span>
          </div>
        ))}

        {/* <div className="run-chart__details__item">
          <span>Top Speed</span>
          <span>{"12"}</span>
        </div>
        <div className="run-chart__details__item">
          <span>Time Top Speed</span>
          <span>{"12"}</span>
        </div>
        <div className="run-chart__details__item">
          <span>Distance</span>
          <span>{"12"}</span>
        </div>
        <div className="run-chart__details__item">
          <span>Time</span>
          <span>{"12"}</span>
        </div> */}
      </div>
    </ItemContainer>
  );
};
