import React, {useContext, useState} from "react";
import {ITeam} from "@/interfaces/interface";
import {Form, Modal} from "@/shared";
import {CustomerContext, ModalContext} from "@/contexts";
import "./DeleteTeamModal.scss";
import Api from "@/api/api";
import {toast} from "react-toastify";


interface Props {
  team: ITeam;
}

export function DeleteTeamModal({team}: Props) {
  const [loading, setLoading] = useState(false);

  const {closeModal} = useContext(ModalContext);
  const {getTeams} = useContext(CustomerContext);

  const handleTeamDelete = async () => {
    setLoading(true);
    try {
      await Api.delete(`v2/organization-admin/organizations/teams/${team.id}`);
      toast.success(
        "Team deleted successfully"
      );
      closeModal();
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setLoading(false);
      await getTeams();
    }
  }

  return (
    <Modal className="delete-team-modal">
      <Modal.Header text={`Are you sure you want to remove Team#${team?.pin} from the records?`}/>
      <Modal.Footer>
        <Form.Button type="outline" loading={loading} onClick={closeModal}>
          CANCEL
        </Form.Button>
        <Form.Button type="cancel" onClick={handleTeamDelete}>
          DELETE
        </Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
