import React, {useState} from "react";
import Api from "../api/api";
import {ISubscription, ISubscriptionOverview, SubscriptionRequest} from "@/interfaces/subscriptions-interfaces";

export interface ISuperadminContext {
  subscriptions: ISubscription[];
  getSubscriptions: () => Promise<void>;
  subscriptionsOverview?: ISubscriptionOverview;
  getSubscriptionsOverview: () => Promise<void>;
  subscriptionRequests: SubscriptionRequest[];
  getSubscriptionsRequests: () => Promise<void>;
}

type Props = {
  children?: React.ReactNode;
};

const SuperadminContext = React.createContext({} as ISuperadminContext);

const SuperadminContextProvider = ({children}: Props) => {
  const [subscriptionsOverview, setSubscriptionsOverview] = useState<ISubscriptionOverview | undefined>()
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([])
  const [subscriptionRequests, setSubscriptionsRequests] = useState<SubscriptionRequest[]>([])

  const getSubscriptionsOverview = async () => {
    const res = await Api.get<ISubscriptionOverview>(
      "/v2/admin/organizations/subscriptions/overview"
    );

    if (res.status === 200) {
      setSubscriptionsOverview(res.data);
    }
  };

  const getSubscriptions = async () => {
    const res = await Api.get<ISubscription[]>(
      "/v2/admin/organizations/subscriptions"
    );

    if (res.status === 200) {
      setSubscriptions(res.data);
    }
  };

  const getSubscriptionsRequests = async () => {
    const res = await Api.get<SubscriptionRequest[]>(
      "/v2/admin/organizations/subscriptions/requests"
    );

    if (res.status === 200) {
      setSubscriptionsRequests(res.data);
    }
  };

  const state = {
    subscriptionsOverview,
    subscriptions,
    subscriptionRequests,
    getSubscriptions,
    getSubscriptionsRequests,
    getSubscriptionsOverview
  };

  return (
    <SuperadminContext.Provider value={state}>
      {children}
    </SuperadminContext.Provider>
  );
};

export {SuperadminContextProvider, SuperadminContext};
