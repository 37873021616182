import {ICoachAthletesRequests} from "@/interfaces/interface";
import {Form, ImageFromAssets} from "@/shared";
import Api from "@/api/api";
import {useContext} from "react";
import {CoachContext} from "@/contexts";
import {getDateInLocal} from "@/helpers";

type Props = {
  athletesByStatus: ICoachAthletesRequests[];
};

export function RequestBodyDeclined({athletesByStatus}: Props) {
  const {getAthletesRequests} = useContext(CoachContext);

  const handleApproveClick = async (username: string, teamId: string) => {
    const res = await Api.patch(
      `v2/coach/organizations/teams/${teamId}/athletes/${username}/approve`
    );
    if (res.status === 200) {
      await getAthletesRequests();
    }
  };

  return (
    <>
      {athletesByStatus.map((athlete, index) => {
        const {timestamp, userData, teamId, teamPin} = athlete;
        const {email = "", name = "Unknown name", username} = userData || {};

        return (
          <div key={index} className="request-body-new">
            <div className="name-column">
              <div>{name}</div>
              <div>{email}</div>
            </div>

            <div className="date-column">{getDateInLocal(timestamp)}</div>
            <div className="team-column">
              <div> Team: {teamPin}</div>
            </div>
            <div className="actions-column">
              <Form.Button
                onClick={() => handleApproveClick(username, teamId)}
                className="approve-button"
              >
                <ImageFromAssets name="user-approve.svg"/>
              </Form.Button>
            </div>
          </div>
        );
      })}
    </>
  );
}
