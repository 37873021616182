import Api from "@/api/api";
import {AddCoachModal} from "@/components/Modals/AddCoachModal/AddCoachModal";
import {AuthContext, CustomerContext, ModalContext} from "@/contexts";
import {ITeam, UserRoleEnum} from "@/interfaces/interface";
import {Form, ImageFromAssets, ItemContainer} from "@shared";
import {useContext, useEffect} from "react";
import {CoachTableItem} from "../../../TableItems/CoachTableItem/CoachTableItem";
import "./TeamCoaches.scss";
import {SelectAddCoachModal} from "@/components/Modals/SelectAddCoachModal/SelectAddCoachModal";
import {CreateCoachModal} from "@/components/Modals/CreateCoachModal/CreateCoachModal";

type Props = {
  selectedTeam: ITeam;
};

export function TeamCoaches({selectedTeam}: Props) {
  const {openModal} = useContext(ModalContext);
  const {getTeams, getCustomerData} = useContext(CustomerContext);
  const {userRole} = useContext(AuthContext);

  const {coaches = [], id} = selectedTeam;

  const handleOpenAddExistingCoachModal = () => {
    openModal(<AddCoachModal selectedTeam={selectedTeam}/>);
  };

  const handleOpenAddNewCoachModal = () => {
    openModal(<CreateCoachModal selectedTeam={selectedTeam}/>);
  };

  const handleOpenAddCoachModal = () => {
    openModal(<SelectAddCoachModal openCoachCreateModal={handleOpenAddNewCoachModal}
                                   openCoachSelectModal={handleOpenAddExistingCoachModal}/>);
  }


  const handleDeleteCoachFromTeam = async (coachUserName: string) => {
    const res = await Api.delete(
      `admin/customers/organizations/${selectedTeam.id}/coach-groups?coachUsername=${coachUserName}`
    );

    if (res.status === 200) {
      await getTeams();
    }
  };

  const coachesExist = !selectedTeam?.coaches?.length;

  const getEmptyText = () => {
    if (!selectedTeam?.id) {
      return "Select some team";
    }
    if (coachesExist) {
      return "Add Some Coach";
    }
  };

  const areSomeCoachExist = coaches.length > 0;

  useEffect(() => {
    if (userRole === UserRoleEnum.ROLE_ORGANIZATION_ADMIN) {
      getCustomerData();
    }
  }, []);

  return (
    <ItemContainer className={`organization-coaches`}>
      <ItemContainer.Header
        className="organization-coaches__header"
        leftSide={<div>Coaches</div>}
        rightSide={
          <Form.Button
            onClick={handleOpenAddCoachModal}
            hidden={!selectedTeam?.id}
          >
            <ImageFromAssets name="user-approve.svg"/>
            ADD COACH
          </Form.Button>
        }
      />

      <div className="organization-coaches__body">
        {areSomeCoachExist && (
          <div>
            {coaches.map((coach, index) => (
              <CoachTableItem
                key={index}
                selectedOrganization={selectedTeam}
                coach={coach}
                handleDeleteCoachFromOrganization={async () => {
                  await handleDeleteCoachFromTeam(coach.username);
                }}
              />
            ))}
          </div>
        )}

        {!areSomeCoachExist && (
          <div className="organization-coaches__body__empty">
            <ImageFromAssets name="empty-coaches.png"/>
            <span>{getEmptyText()}</span>
          </div>
        )}
      </div>
    </ItemContainer>
  );
}
