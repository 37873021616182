import {ICoachAthletesRequests} from "@/interfaces/interface";
import dayjs from "dayjs";
import {Form, ImageFromAssets} from "@/shared";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import Api from "@/api/api";
import {useContext, useState} from "react";
import {CoachContext} from "@/contexts";

dayjs.extend(LocalizedFormat);

type Props = {
  athletesByStatus: ICoachAthletesRequests[];
};

export function RequestBodyApproved({athletesByStatus}: Props) {
  const {getAthletesRequests} = useContext(CoachContext);

  const [loading, setLoading] = useState(false);

  const handleDeclineClick = async (username: string, teamId: string) => {
    try {
      setLoading(true);

      const res = await Api.patch(
        `v2/coach/organizations/teams/${teamId}/athletes/${username}/decline`
      );
      if (res.status === 200) {
        await getAthletesRequests();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {athletesByStatus.map((athlete, index) => {
        const {timestamp, userData, teamId, teamPin} = athlete;
        const {email = "", name = "Unknown name", username} = userData || {};
        return (
          <div key={username + index} className="request-body-new">
            <div className="name-column">
              <div>{name}</div>
              <div>{email}</div>
            </div>

            <div className="date-column">
              {dayjs(new Date(timestamp)).format("lll")}
            </div>
            <div className="team-column">
              <div> Team: {teamPin}</div>
            </div>
            <div className="actions-column">
              <Form.Button
                type="decline"
                onClick={() => handleDeclineClick(username, teamId)}
              >
                <ImageFromAssets name="user-decline.svg"/>
              </Form.Button>
            </div>
          </div>
        );
      })}
    </>
  );
}
