import {ICoachAthletesRequests, UserStatusEnum} from "@/interfaces/interface";
import {ApprovedRequestHeader} from "./ApprovedRequestHeader/ApprovedRequestHeader";
import {AutoApproveRequestHeader} from "./AutoApproveRequestHeader/AutoApproveRequestHeader";
import {DeclineRequestHeader} from "./DeclineRequestHeader/DeclineRequestHeader";
import {NewRequestHeader} from "./NewRequestHeader/NewRequestHeader";

type Props = {
  activeStatus: UserStatusEnum;
  athletesByStatus: ICoachAthletesRequests[];
};

export function RequestsBodyHeader({activeStatus, athletesByStatus}: Props) {
  const headerByStatus: Record<
    UserStatusEnum,
    | typeof ApprovedRequestHeader
    | typeof DeclineRequestHeader
    | typeof NewRequestHeader
    | typeof AutoApproveRequestHeader
  > = {
    [UserStatusEnum.APPROVED]: ApprovedRequestHeader,
    [UserStatusEnum.DECLINED]: DeclineRequestHeader,
    [UserStatusEnum.PENDING]: NewRequestHeader,
    [UserStatusEnum.AUTO_APPROVED]: AutoApproveRequestHeader,
  };

  const Header = headerByStatus[activeStatus];

  return <Header athletesByStatus={athletesByStatus}/>;
}
