import {ItemContainer} from "@shared";
import "./OverviewSubscriptions.scss";
import {FC} from "react";
import {PlanEnum} from "@/interfaces/subscriptions-interfaces";

type Props = {
  title: string;
  value?: number;
  status?: PlanEnum;
};

export const OverviewSubscriptions: FC<Props> = ({title, value, status}) => {
  const [mainText, pinText] = title.split(/(\d+ Pins)/);

  return (
    <ItemContainer
      className='overview-subscription-item'
    >
      <div className='title'>
        <div className='title-text'>
          {mainText}<span className="title-text-pin" style={{display:'block'}}>{pinText}</span>
        </div>
      </div>
      <div className='value'>
        <div className='value-text'>{value}</div>
        {value === 1 ? 'organization' : 'organizations'}
      </div>
    </ItemContainer>
  );
}

OverviewSubscriptions.defaultProps = {
  value: 0
}
