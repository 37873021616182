import React, {useContext} from "react";
import {Form, Modal} from "@/shared";
import {ModalContext,} from "@/contexts";
import "./UpdateYourPlanModal.scss";

interface Props {
  page: string
}

export function UpdateYourPlanModal({page}: Props) {
  const {closeModal} = useContext(ModalContext);

  return (
    <Modal className="update-plan-modal">
      <Modal.Header
        className='update-plan-modal__header'
        text='Update your plan!'
      />
      <div className='update-plan-modal__title'>
        To have access to {page} module, you should update your plan
      </div>
      <div className='update-plan-modal__subtitle'>or contact our support team:</div>
      <Form.Button
        type="link"
        className='edit-athlete-modal__delete--blue'
        onClick={(e) => {
          e.preventDefault();
          window.location.href = 'mailto:support@shredmill.com';
        }}
      >
        support@shredmill.com
      </Form.Button>
      <Modal.Footer>
        <Form.Button className='update-plan-modal__button' type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
