import Api from "@/api/api";
import {ModalContext} from "@/contexts";
import {IGetClientsResponse,} from "@/interfaces/interface";
import {Form, Modal} from "@/shared";
import {useContext, useState} from "react";
import "./AddClientModal.scss";
import {toast} from "react-toastify";

type FormErrorsType = {
  email?: string;
  name?: string;
  contactInfo?: string;
  billingInfo?: string;
};

type FormValueType = {
  email: string;
  name: string;
  contactInfo: string;
  billingInfo: string;
};

type Props = {
  getClients: () => Promise<void>;
};

export function AddClientModal({getClients}: Props) {
  const {closeModal} = useContext(ModalContext);

  const [loading, setLoading] = useState(false);

  const [formError, setFormError] = useState<FormErrorsType>({});

  const [formValue, setFormValue] = useState<FormValueType>({
    email: "",
    name: "",
    contactInfo: "",
    billingInfo: "",
  });

  const {email, name, billingInfo, contactInfo} = formValue;

  const validate = () => {
    const errors: FormErrorsType = {};
    const requiredFields: Partial<keyof FormErrorsType>[] = ["email", "name"];
    requiredFields.forEach((field) => {
      if (!formValue[field]) {
        errors[field] = "Required field";
      }
    });

    if (name?.length < 5) {
      errors.name = "Admin Name should be longer than 4 symbols";
    }
    if (contactInfo?.length < 5) {
      errors.contactInfo = "Contact info should be longer than 4 symbols";
    }
    if (billingInfo?.length < 5) {
      errors.billingInfo = "Billing info should be longer than 4 symbols";
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(email)) {
      errors.email = "Invalid email address";
    }

    if (Object.keys(errors).length) {
      return errors;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;

    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleAddCoach = async () => {
    const errors = validate();
    if (errors) {
      setFormError(errors);
      return;
    }
    setLoading(true);

    try {
      const res = await Api.post<IGetClientsResponse>(`/admin/customers`, {
        name,
        email,
        billingInfo,
        contactInfo,
      });
      if (res.status === 200) {
        await getClients();
        closeModal();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Some error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal>
      <Modal.Header text="Add Org Admin"/>

      <Form.Row>
        <Form.Label>Org Admin Name</Form.Label>
        <Form.Input
          maxLength={40}
          name="name"
          placeholder="Enter name"
          value={name}
          errorMessage={formError.name}
          onChange={handleChange}
        />
      </Form.Row>

      <Form.Row>
        <Form.Label>Email address</Form.Label>
        <Form.Input
          name="email"
          placeholder="Enter email address"
          value={email}
          errorMessage={formError.email}
          onChange={handleChange}
        />
      </Form.Row>

      <Form.Row>
        <Form.Label>Contact info</Form.Label>
        <Form.Input
          value={contactInfo || ""}
          errorMessage={formError.contactInfo}
          placeholder="Enter contact info"
          name={"contactInfo"}
          maxLength={50}
          onChange={handleChange}
        />
      </Form.Row>

      <Form.Row>
        <Form.Label>Billing info</Form.Label>
        <Form.Input
          value={billingInfo || ""}
          placeholder="Enter billing info"
          errorMessage={formError.billingInfo}
          name={"billingInfo"}
          maxLength={50}
          onChange={handleChange}
        />
      </Form.Row>

      <Modal.Footer>
        <Form.Button type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>

        <Form.Button loading={loading} onClick={handleAddCoach}>
          ADD
        </Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
