import {ImageFromAssets} from "@/shared";
import "./EmptyContainer.scss";

type Props = {
  className?: string;
  text?: string;
  imageName?: string;
};

export const EmptyContainer = ({
                                 imageName = "empty-coaches.png",
                                 text = "",
                               }: Props) => {
  return (
    <div style={{height: '100%'}}>
      <div className={`empty-container`}>
        <ImageFromAssets className="empty-container__image" name={imageName}/>
        <div>{text}</div>
      </div>
    </div>
  );
};
