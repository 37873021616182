import Api from "@/api/api";
import {RunChart} from "@/components/Charts/RunChart/RunChart";
// import { RunChart } from "@/components/";
import {ConfirmationModal} from "@/components/Modals/ConfirmationModal/ConfirmationModal";
import {ModalContext} from "@/contexts";
import {getDateInLocal, getSpeedInNeededUnits} from "@/helpers";
import {IRun} from "@/interfaces/interface";
import {ImageFromAssets} from "@/shared";
import {useContext, useState} from "react";
import "./Run.scss";

type Props = {
  run: IRun;
  runTitle: string;
  assessmentID: string;
};

export function Run({run, runTitle, assessmentID}: Props) {
  const {openModal} = useContext(ModalContext);

  const [showGraph, setShowGraph] = useState(false);

  const {
    distance,
    distanceUnit,
    time,
    speedByTime,
    timeToTopSpeed,
    timestamp,
    topSpeed,
    id,
  } = run;

  const handleDeleteRun = () => {
    const deleteRun = async () => {
      try {
        const res = await Api.delete(
          `/admin/assessments/${assessmentID}/runs/${id}`
        );
      } catch (error) {
      } finally {
      }
    };

    openModal(
      <ConfirmationModal callback={deleteRun} headerText="Are you sure?"/>
    );
  };

  return (
    <>
      <div
        onClick={() => {
          setShowGraph((prev) => !prev);
        }}
        className="run"
      >
        <div>{`${runTitle} (${getDateInLocal(timestamp)})`}</div>

        <div>{`${getSpeedInNeededUnits(topSpeed, "yards")} (MPH)`}</div>

        <div className="run__actions">
          <div className="run__actions__graph">
            {showGraph ? "Hide Graph" : "Show Graph"}
          </div>

          <div>
            <ImageFromAssets
              onClick={handleDeleteRun}
              name="actions-trash.svg"
            />
          </div>
        </div>
      </div>

      {showGraph && (
        // <div>
        <RunChart runs={[run]}/>
        // </div>
      )}
    </>
  );
}
