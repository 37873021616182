import {ICoach} from "@/interfaces/interface";
import {Form, ImageFromAssets} from "@/shared";
import dayjs from "dayjs";
import "./RequestBodyNew.scss";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import Api from "@/api/api";
import {useContext} from "react";
import {CustomerContext} from "@/contexts";
import {getDateInLocal} from "@/helpers";

dayjs.extend(LocalizedFormat);

type Props = {
  coachesByStatus: ICoach[];
};

export function RequestBodyNew({coachesByStatus}: Props) {
  const {getCustomerData} = useContext(CustomerContext);

  const handleApproveClick = async (username: string) => {
    const res = await Api.post(`admin/customers/coaches/${username}/approve`);
    if (res.status === 200) {
      await getCustomerData();
    }
  };
  const handleDeclineClick = async (username: string) => {
    const res = await Api.post(`admin/customers/coaches/${username}/decline`);
    if (res.status === 200) {
      await getCustomerData();
    }
  };

  return (
    <>
      {coachesByStatus.map((coach, index) => {
        const {timestamp, userData, username} = coach;
        const {email = "", name = "Unknown name"} = userData || {};

        return (
          <div key={username + index} className="request-body-new">
            <div className="name-column">
              <div>{name}</div>
              <div>{email}</div>
            </div>

            <div className="date-column">{getDateInLocal(timestamp)}</div>
            <div/>

            <div className="actions-column">
              <Form.Button
                onClick={() => handleApproveClick(username)}
                className="approve-button"
              >
                <ImageFromAssets name="user-approve.svg"/>
              </Form.Button>
              <Form.Button
                onClick={() => handleDeclineClick(username)}
                className="decline-button"
              >
                <ImageFromAssets name="user-decline.svg"/>
              </Form.Button>
            </div>
          </div>
        );
      })}
    </>
  );
}
