import {lazy} from "react";

import {IRoute, UserRoleEnum} from "./interfaces/interface";
import {PlanEnum} from "@/interfaces/subscriptions-interfaces";

//SHARED
const LoginPage = lazy(() => import("./pages/LoginPage/LoginPage"));

//CUSTOMER

const RequestPage = lazy(
  () => import("@/pages/OrgAdminPages/RequestPage/RequestPage")
);
const AthleteRequestPage = lazy(
  () => import("@/pages/OrgAdminPages/AthleteRequestPage/AthleteRequestPage")
);

const OrganizationPage = lazy(
  () => import("@/pages/OrgAdminPages/TeamsPage/TeamsPage")
);

const ClientInfoPage = lazy(
  () => import("@/pages/OrgAdminPages/ClientInfoPage/ClientInfoPage")
);

//SUPERADMIN
const ClientManagementPage = lazy(
  () => import("@/pages/SuperAdminPages/ClientManagementPage/ClientManagementPage")
);
const SubscriptionsPage = lazy(
  () => import('@/pages/SuperAdminPages/SubscriptionsPage/SubscriptionsPage')
);

//COACH

const CoachClientInfoPage = lazy(
  () => import("./pages/CoachPages/CoachClientInfoPage/CoachClientInfoPage")
);

const CoachRunsPage = lazy(
  () => import("./pages/CoachPages/CoachRunsPage/CoachRunsPage")
);
const CoachAthleteRequestPage = lazy(
  () =>
    import("./pages/CoachPages/CoachAthleteRequestPage/CoachAthleteRequestPage")
);
const CoachOrganizationPage = lazy(
  () => import("@/pages/CoachPages/CoachTeamsPage/CoachTeamsPage")
);
const AdminOrganizationPage = lazy(
  () => import("@/pages/SuperAdminPages/AdminTeamPage/AdminTeamPage")
);

export const signOutRoutes: IRoute[] = [
  {
    to: "/",
    title: "Login",
    iconName: "nav-overview.png",
    component: <LoginPage/>,
  },
];

type RoutesByRole = {
  [key in UserRoleEnum]: IRoute[];
};

export const deniedPlans = [PlanEnum.FREE_VERSION, PlanEnum.SELF_PROFILING];

export const signedInRoutes: RoutesByRole = {
  [UserRoleEnum.ROLE_ADMIN]: [
    {
      to: "/",
      title: "Org Admins",
      iconName: "nav-info.png",
      component: <ClientManagementPage/>,
    },
    {
      to: "/teams",
      title: "Teams",
      iconName: "nav-organizations.png",
      component: <AdminOrganizationPage/>,
    },
    {
      to: "/subscriptions",
      title: "Subscriptions",
      iconName: "shield-check.svg",
      component: <SubscriptionsPage/>,
    },
  ],
  [UserRoleEnum.ROLE_ORGANIZATION_ADMIN]: [
    {
      to: "/",
      title: "Coaches' Requests",
      iconName: "nav-request.png",
      component: <RequestPage/>,
      // deniedPlans,
    },
    {
      to: "/athletes",
      title: "Athletes' Requests",
      iconName: "nav-request.png",
      component: <AthleteRequestPage/>,
      deniedPlans,
    },
    {
      to: "/teams",
      title: "Teams",
      iconName: "nav-organizations.png",
      component: <OrganizationPage/>,
      // deniedPlans,
    },
    {
      to: "/info",
      title: "Org Admin Info",
      iconName: "nav-info.png",
      component: <ClientInfoPage/>,
    },
  ],
  [UserRoleEnum.ROLE_COACH]: [
    {
      to: "/",
      title: "Athletes' Requests",
      iconName: "nav-request.png",
      component: <CoachAthleteRequestPage/>,
      deniedPlans,
    },
    {
      to: "/teams",
      title: "Teams",
      iconName: "nav-organizations.png",
      component: <CoachOrganizationPage/>,
      deniedPlans,
    },
    {
      to: "/info",
      title: "Coach Info",
      iconName: "nav-info.png",
      component: <CoachClientInfoPage/>,
    },
  ],
  [UserRoleEnum.ROLE_ATHLETE]: [],
};
