import dayjs from "dayjs";

export const getLeftDays = (dateTo: string) => {
  const now = dayjs();
  const endDate = dayjs(dateTo);
  const diff = endDate.diff(now, 'day');

  if (diff > 0) {
    return (`left ${diff} days`);
  } else {
    return ('Time has passed.');
  }
}