import {OverviewEditItems} from "../../OverviewEditItems/OverviewEditItems";
import {OverviewTableItem} from "../OverviewTableItem";
import "./OverviewTableItemAthletes.scss";

type Props = {
  name: string;
  status: string;
};

export function OverviewTableItemAthletes({name, status}: Props) {
  return (
    <OverviewTableItem
      leftSide={<div className="overview-table-item-left-side">{name}</div>}
      rightSide={
        <div className="overview-table-item-right-side">
          <div
            className={`right-side-initial athlete-status athlete-status--${status.toLocaleLowerCase()}`}
          >
            {status}
          </div>
          <div className={`right-side-secondary`}>
            <OverviewEditItems/>
          </div>
        </div>
      }
    />
  );
}
