import React from "react";
import "./InfoListItem.scss";

interface Props {
  title: string;
  primaryText: string;
  subtext?: string;
}

export function InfoListItem ({title, subtext, primaryText}: Props) {
  return (
    <div className='info-list-item'>
      <>{title}</>
      <div className='info-list-item__left'>
        <span className='info-list-item__left--primary'>{primaryText}</span>
        {subtext && <span className='info-list-item__left--subtext'>{subtext}</span>}
      </div>
    </div>
  );
}
