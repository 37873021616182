import "./SubscriptionPicker.scss";
import {CustomerContext, ModalContext} from "@/contexts";
import {useContext} from "react";
import {FormButton} from "@/shared/Form/FormButton/FormButton";
import {PlanMappingEnum} from "@/interfaces/subscriptions-interfaces";
import {ImageFromAssets} from "@/shared";
import {SubscriptionInfoModal} from "@/components/Modals/SubscriptionInfoModal/SubscriptionInfoModal";

interface Props {
}

export const SubscriptionPicker = ({}: Props) => {
  const {openModal} = useContext(ModalContext);

  const {customerData, getCustomerData} = useContext(CustomerContext);
  const subscriptionInfo = customerData?.subscriptionInfo;
  const subscriptionLevel = subscriptionInfo?.subscriptionLevel

  const handleOpenSubscriptionInfoModal = () => {
    openModal(<SubscriptionInfoModal/>)
  }

  return (
    <FormButton
      className='subscription-picker'
      onClick={() => handleOpenSubscriptionInfoModal()}
    >
      <ImageFromAssets name='file-text-shield.svg'/>
      <span className='subscription-picker__text'>
        {subscriptionLevel && PlanMappingEnum[subscriptionLevel].toUpperCase()}
      </span>
      <ImageFromAssets name='arrow-down-small.svg'/>
    </FormButton>
  );
};
