import dayjs from "dayjs";

var localizedFormat = require("dayjs/plugin/localizedFormat");

dayjs.extend(localizedFormat);

export const getDateInLocal = (timestamp: Date) => {
  let curDate = new Date(timestamp);
  const hours = curDate.getHours();

  const timeZone = new Date().getTimezoneOffset() / 60;

  curDate.setHours(hours - timeZone);

  return dayjs(curDate).format("lll");
};
