// PROD

const baseURL = "https://dashboard-api.shredmill.com/api/";

const awsConfig = {
  identityPoolId: "<Cognito Identity Pool ID>",
  region: "<Region>",
  userPoolId: "us-east-1_ECH7A6J4c",
  userPoolWebClientId: "mu9g9odsckskr1r0eh2bip4pe",
};

//DEV

// const baseURL = "http://34.204.48.212:8080/api/";
//
// const awsConfig = {
//   identityPoolId: "<Cognito Identity Pool ID>",
//   region: "<Region>",
//   userPoolId: "us-east-1_f7abhe1XP",
//   userPoolWebClientId: "5tmrs840jk54t58ctefmp3nscu",
// };

export { awsConfig, baseURL };
