import {ImageFromAssets} from "@/shared";
import "./EmptyItemContainer.scss";

type Props = {
  className?: string;
  text?: string;
  imageName?: string;
};

export const EmptyItemContainer = ({imageName = "empty-organization.png", text = ""}: Props) => {
  return (
    <div className='empty-item-container'>
      <div></div>
      <ImageFromAssets name={imageName} className='empty-item-container__image'/>
      <div className='empty-item-container__text'>{text}</div>
    </div>
  );
};
