import './SubscriptionRequestsTable.scss'
import {FC, useContext, useEffect} from "react";
import {SuperadminContext} from "@/contexts";
import {
  SubscriptionRequestsTableItem
} from "@/components/TableItems/SubscriptionRequestsTableItem/SubscriptionRequestsTableItem";
import Api from "@/api/api";
import {toast} from "react-toastify";

interface Props {
}

export const SubscriptionRequestsTable: FC<Props> = () => {
  const {subscriptionRequests, getSubscriptionsRequests, getSubscriptionsOverview} = useContext(SuperadminContext)
  const reversedRequests = [...subscriptionRequests].reverse();

  useEffect(() => {
    const init = async () => {
      await getSubscriptionsRequests()
    };
    init();
  }, []);

  const handleRequest = async (orgId: string, action: 'accept' | 'reject') => {
    const updateUrl = `/v2/admin/organizations/${orgId}/subscription/requests/${action}`
    try {
      await Api.patch(updateUrl,);
      getSubscriptionsOverview();
      getSubscriptionsRequests();
    } catch (error: any) {
      const {message} = error?.response?.data;
      if (message) {
        toast.error(message);
      } else {
        toast.error("Something went wrong");
      }
    } finally {
    }
  }

  const handleRequestApprove = async (orgId: string) => {
    handleRequest(orgId, 'accept')
  }

  const handleRequestDecline = async (orgId: string) => {
    handleRequest(orgId, 'reject')
  }

  return (
    <div className='subscription-requests-list__list'>
      <div className='subscription-requests-list__list--header'>
        <div>Organizations</div>
        <div>ID</div>
        <div>Selected payment plan</div>
        <div>Expired date</div>
        <div>Q-ty of teams</div>
        <div>Q-ty of coaches</div>
        <div>Q-ty of athletes (pins)</div>
        <div/>
      </div>
      {reversedRequests.map(item =>
        <SubscriptionRequestsTableItem
          subscription={item}
          handleSubscriptionApprove={handleRequestApprove}
          handleSubscriptionDecline={handleRequestDecline}
          key={item.id}
        />
      )}
    </div>
  )
}