import Api from "@/api/api";
import {CustomerContext, ModalContext} from "@/contexts";
import {Form} from "@/shared";
import {Modal} from "@/shared/Modal/Modal";
import {useContext, useState} from "react";
import "./EditClientModal.scss";
import {toast} from "react-toastify";
import {IClient} from "@/interfaces/interface";

type Props = {
  client: IClient;
  getClients: () => Promise<void>;
};

type FormValue = {
  name: string;
  contactInfo: string;
  billingInfo: string;
};

type FormErrorsType = {
  name?: string;
  contactInfo?: string;
  billingInfo?: string;
};

const initialState = {
  name: "",
  contactInfo: "",
  billingInfo: "",
};

export function EditClientModal({client, getClients}: Props) {
  const {closeModal} = useContext(ModalContext);
  const {getTeams} = useContext(CustomerContext);

  const [loading, setLoading] = useState(false);

  const [formValue, setFormValue] = useState<FormValue>({
    name: client.name,
    contactInfo: client.contactInfo || "",
    billingInfo: client.billingInfo || "",
  });

  const [formError, setFormError] = useState<FormErrorsType>({});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const {name, contactInfo, billingInfo} = formValue;

  const validate = () => {
    const errors: FormErrorsType = {};

    if (name?.length < 5) {
      errors.name = "Admin Name should be longer than 4 symbols";
    }
    if (contactInfo?.length < 5) {
      errors.contactInfo = "Contact info should be longer than 4 symbols";
    }
    if (billingInfo?.length < 5) {
      errors.billingInfo = "Billing info should be longer than 4 symbols";
    }

    if (Object.keys(errors).length) {
      return errors;
    }
  };

  const handleConfirm = async () => {
    const errors = validate();
    if (errors) {
      setFormError(errors);
      return;
    }
    setLoading(true);

    try {
      const res = await Api.put(`/v2/admin/organizations/${client.id}`, {
        name,
        contactInfo,
        billingInfo,
      });
      if (res.status === 200) {
        await getClients();
        closeModal();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Some error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal>
      <Modal.Header text="Edit Org Admin Info"/>

      <Form.Row>
        <Form.Label>Org Admin Name</Form.Label>
        <Form.Input
          errorMessage={formError.name}
          onChange={handleChange}
          value={name}
          name={"name"}
          maxLength={40}
        />
      </Form.Row>

      <Form.Row>
        <Form.Label>Contact info</Form.Label>
        <Form.Input
          errorMessage={formError.contactInfo}
          placeholder="ZIP, LA, Pedastrian street, +12064512559"
          onChange={handleChange}
          value={contactInfo}
          name={"contactInfo"}
          maxLength={50}
        />
      </Form.Row>

      <Form.Row>
        <Form.Label>Billing info</Form.Label>
        <Form.Input
          errorMessage={formError.billingInfo}
          placeholder="ZIP, LA, Pedastrian street, +12064512559"
          onChange={handleChange}
          value={billingInfo}
          name={"billingInfo"}
          maxLength={50}
        />
      </Form.Row>

      <Modal.Footer>
        <Form.Button type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>

        <Form.Button onClick={handleConfirm}>SAVE</Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
