import React, {useContext, useState} from "react";
import {EditAthleteForm, IAthleteUserData, IUser,} from "@/interfaces/interface";
import {Form, Modal} from "@/shared";
import {AuthContext, CoachContext, CustomerContext, ModalContext,} from "@/contexts";
import {toast} from "react-toastify";
import "./EditAthleteModal.scss";
import Api from "@/api/api";
import {DeleteAthleteModal} from "@/components/Modals/DeleteAthleteModal/DeleteAthleteModal";
import {DeleteAthleteFromTeamModal} from "@/components/Modals/DeleteAthleteFromTeamModal/DeleteAthleteFromTeamModal";

interface Props {
  athlete: IAthleteUserData | IUser;
  teamId: string
}

type FormErrors = {
  email?: string;
  name?: string;
  weight?: string;
  height?: string;
  gender?: string;
};

export function EditAthleteModal({athlete, teamId}: Props) {
  const [formValues, setFormValues] = useState<EditAthleteForm>(
    new EditAthleteForm(athlete)
  );
  const [formError, setFormError] = useState<FormErrors>({});
  const [loading, setLoading] = useState(false);
  const {closeModal, openModal} = useContext(ModalContext);
  const {userRole} = useContext(AuthContext);
  const {getTeams} = useContext(CoachContext);
  const {getTeams: getCustomerTeams} = useContext(CustomerContext);

  const isCoach = userRole === "ROLE_COACH";

  const {email, name, weight, height, gender} = formValues;

  const handleFormUpdate = (
    property: string,
    value: string | number | null | undefined
  ) => {
    setFormValues({...formValues, [property]: value});
  };

  const validate = () => {
    const errors: FormErrors = {};
    const requiredFields: Partial<keyof FormErrors>[] = [
      "name",
      "weight",
      "height",
      "gender",
    ];
    requiredFields.forEach((field) => {
      if (!formValues[field]) {
        errors[field] = "Required field";
      }
    });

    const decimalRegex = /^[0-9]*\.?[0-9]{1,1}$/;

    if (Object.keys(errors).length) {
      return errors;
    }

    if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(email)) {
      errors.email = "Invalid email address";
      return errors;
    }

    if (weight && !decimalRegex.test(weight.toString())) {
      errors.weight = "Invalid number";
      toast.error(
        "Weight should be a decimal number. Use only numbers and one dot. Shouldn't end with a dot. One number after the dot."
      );
      return errors;
    }

    if (height && !decimalRegex.test(height.toString())) {
      errors.height = "Invalid number";
      toast.error(
        "Height should be a decimal number. Use only numbers and one dot. Shouldn't end with a dot. One number after the dot."
      );
      return errors;
    }
  };

  const handleAthleteUpdate = async () => {
    const errors = validate();
    if (errors) {
      setFormError(errors);
      return;
    }

    setFormError({});
    const updateUrl = isCoach
      ? "/v2/coach/organizations/teams/athletes/"
      : "/v2/organization-admin/organizations/teams/athletes/";
    setLoading(true);
    try {
      await Api.put(`${updateUrl + athlete.username}`, formValues);
      if (isCoach) {
        getTeams();
      } else {
        getCustomerTeams();
        closeModal();
      }
      closeModal();
    } catch (error: any) {
      const {message, status} = error?.response?.data;
      if (message) {
        toast.error(message);
      } else {
        toast.error("Something went wrong");
      }
      if (status === 'NOT_FOUND') {
        closeModal();
        if (isCoach) {
          getTeams();
        } else {
          getCustomerTeams();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleHardAthleteDelete = () => {
    openModal(<DeleteAthleteModal athlete={athlete}/>);
  }

  const handleAthleteDeleteFromTeam = () => {
    openModal(<DeleteAthleteFromTeamModal athlete={athlete} teamId={teamId}/>);
  }

  return (
    <Modal className="edit-athlete-modal">
      <Modal.Header text="Edit Athlete"/>
      <Form className="edit-athlete-modal__form">
        <Form.Row>
          <Form.Label>Email</Form.Label>
          <Form.Input
            value={formValues.email}
            onChange={({target}) => handleFormUpdate("email", target.value)}
            errorMessage={formError.email}
            placeholder="example@mail.com"
          />
        </Form.Row>
        <Form.Row>
          <Form.Label>Name</Form.Label>
          <Form.Input
            value={name}
            onChange={({target}) => handleFormUpdate("name", target.value)}
            errorMessage={formError.name}
            placeholder="Enter name"
          />
        </Form.Row>
        <Form.Row>
          <Form.Label>Weight (lbs)</Form.Label>
          <Form.Input
            value={weight}
            type="number"
            onChange={({target}) => handleFormUpdate("weight", target.value)}
            errorMessage={formError.weight}
            placeholder="0.0"
          />
        </Form.Row>
        <Form.Row>
          <Form.Label>Height (inches)</Form.Label>
          <Form.Input
            value={height}
            type="number"
            onChange={({target}) => handleFormUpdate("height", target.value)}
            errorMessage={formError.height}
            placeholder="0.0"
          />
        </Form.Row>
        <Form.Row>
          <Form.Label>Gender</Form.Label>
          <Form.Select
            value={gender}
            onChange={(gender) => handleFormUpdate("gender", gender)}
            options={[
              {text: "Male", value: "Male"},
              {text: "Female", value: "Female"},
            ]}
            errorMessage={formError.gender}
          />
        </Form.Row>
      </Form>
      <div style={{display: 'flex', width: '100%', gap: '32px'}} className='edit-athlete-modal__delete'>
        <Form.Button type="link" onClick={handleHardAthleteDelete} className='edit-athlete-modal__delete--red'>
          Delete account
        </Form.Button>
        <Form.Button type="link" onClick={handleAthleteDeleteFromTeam} className='edit-athlete-modal__delete--blue'>
          Delete from team
        </Form.Button>
      </div>
      <Modal.Footer>
        <Form.Button type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>
        <Form.Button loading={loading} onClick={handleAthleteUpdate}>
          UPDATE ATHLETE
        </Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
