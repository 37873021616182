import Api from "@/api/api";
import {CustomerContext} from "@/contexts";
import {ICoach} from "@/interfaces/interface";
import {Form, ImageFromAssets, ItemContainer} from "@/shared/";
import {useContext, useState} from "react";
import "./ApprovedRequestHeader.scss";

type Props = {
  coachesByStatus: ICoach[];
};

export function ApprovedRequestHeader({coachesByStatus}: Props) {
  const [loading, setLoading] = useState(false);

  const approveAllVisible = coachesByStatus.length > 0;

  const {getCustomerData} = useContext(CustomerContext);

  const handleDeclineAllClick = async () => {
    try {
      setLoading(true);

      const res = await Api.post(`admin/customers/coaches/decline/bulk`, {
        coachUsernames: coachesByStatus.map((coach) => coach.username),
      });
      if (res.status === 200) {
        await getCustomerData();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <ItemContainer.Header
      className="new-request-header"
      leftSide={<div>Approved Requests</div>}
      rightSide={
        <Form.Button
          onClick={handleDeclineAllClick}
          // hidden={!approveAllVisible}
          hidden={true}
          type="decline"
          loading={loading}
        >
          <ImageFromAssets name="user-decline.svg"/>
          DECLINE ALL
        </Form.Button>
      }
    />
  );
}
