import {ICoach} from "@/interfaces/interface";
import {Form, ImageFromAssets} from "@/shared";
import Api from "@/api/api";
import {useContext, useState} from "react";
import {CustomerContext} from "@/contexts";
import {getDateInLocal} from "@/helpers";

type Props = {
  coachesByStatus: ICoach[];
};

export function RequestBodyApproved({coachesByStatus}: Props) {
  const {getCustomerData} = useContext(CustomerContext);

  const [loading, setLoading] = useState(false);

  const handleDeclineClick = async (username: string) => {
    try {
      setLoading(true);

      const res = await Api.post(`admin/customers/coaches/${username}/decline`);
      if (res.status === 200) {
        await getCustomerData();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {coachesByStatus.map((coach, index) => {
        const {timestamp, userData, username} = coach;
        const {email = "", name = "Unknown name"} = userData || {};

        return (
          <div key={username + index} className="request-body-new">
            <div className="name-column">
              <div>{name}</div>
              <div>{email}</div>
            </div>

            <div className="date-column">{getDateInLocal(timestamp)}</div>
            <div/>
            <div className="actions-column">
              <Form.Button
                type="decline"
                onClick={() => handleDeclineClick(username)}
              >
                <ImageFromAssets name="user-decline.svg"/>
              </Form.Button>
            </div>
          </div>
        );
      })}
    </>
  );
}
