import {Form, ImageFromAssets, ItemContainer} from "@/shared/";
import "./DeclineRequestHeader.scss";
import {CustomerContext} from "@/contexts";
import {useContext, useState} from "react";
import {ICoachAthletesRequests} from "@/interfaces/interface";

type Props = {
  athletesByStatus: ICoachAthletesRequests[];
};

export function DeclineRequestHeader({athletesByStatus}: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const {getCustomerData} = useContext(CustomerContext);

  const handleApproveAllClick = async () => {
    // try {
    //   setLoading(true);
    //   const res = await Api.post(`admin/customers/coaches/approve/bulk`, {
    //     coachUsernames: athletesByStatus.map((athlete) => athlete.username),
    //   });
    //   if (res.status === 200) {
    //     await getCustomerData();
    //   }
    // } catch (error) {
    // } finally {
    //   setLoading(false);
    // }
  };

  const approveAllVisible = athletesByStatus.length > 0;

  return (
    <ItemContainer.Header
      className="new-request-header"
      leftSide={<div>Declined Requests</div>}
      rightSide={
        <Form.Button
          onClick={handleApproveAllClick}
          hidden={true}
          // hidden={!approveAllVisible}
          loading={loading}
        >
          <ImageFromAssets name="user-approve.svg"/>
          APPROVE ALL
        </Form.Button>
      }
    />
  );
}
