export enum PlanEnum {
  FREE_VERSION = 'FREE_VERSION',
  SELF_PROFILING = 'SELF_PROFILING',
  TRACKING_PINS_5 = 'TRACKING_PINS_5',
  TRACKING_PINS_100 = 'TRACKING_PINS_100',
  TRACKING_PINS_250 = 'TRACKING_PINS_250',
  TRACKING_PINS_999 = 'TRACKING_PINS_999'
}

export enum PlanMappingEnum {
  FREE_VERSION = 'Free Version',
  SELF_PROFILING = 'Self-Profiling',
  TRACKING_PINS_5 = 'Tracking 5 Pins',
  TRACKING_PINS_100 = 'Tracking 100 Pins',
  TRACKING_PINS_250 = 'Tracking 250 Pins',
  TRACKING_PINS_999 = 'Tracking 999 Pins'
}

export enum SubscriptionPeriodEnum {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  NO_LIMIT = 'NO_LIMIT'
}

export enum SubscriptionReqStatusEnum {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED'
}


export interface ISubscriptionOverview {
  freeVersionSubscription: SubscriptionOverview
  selfProfilingSubscription: SubscriptionOverview
  trackingPins100Subscription?: SubscriptionOverview
  trackingPins5Subscription?: SubscriptionOverview
  trackingPins250Subscription: SubscriptionOverview
  trackingPins999Subscription: SubscriptionOverview
}

export interface SubscriptionOverview {
  subscriptionLevel: PlanEnum
  numberOfOrganizations: number
}

export interface ISubscription {
  id: string
  pin: string
  name: string
  subscriptionInfo: SubscriptionInfo
  numberOfTeams: number
  numberOfCoaches: number
  numberOfAthletePins: number
}

export interface SubscriptionInfo {
  subscriptionLevel: PlanEnum
  effectiveDateTime: string
  expirationDateTime: string | null
}

export interface SubscriptionRequest {
  id: string
  pin: string
  name: string
  subscriptionInfo: SubscriptionInfo
  numberOfTeams: number
  numberOfCoaches: number
  numberOfAthletePins: number
  changeSubscriptionRequest: ChangeSubscriptionRequest
}


export interface UserSubscriptionInfo extends SubscriptionInfo {
  period: number,
  subscriptionPeriod: SubscriptionPeriodEnum,
  pinUsers: number
}

export interface ChangeSubscriptionRequest {
  subscriptionLevel: PlanEnum;
  status?: SubscriptionReqStatusEnum;
}

export interface CustomerSubscriptionInfo {
  subscriptionLevel: PlanEnum
  period: number
  subscriptionPeriod: SubscriptionPeriodEnum
  effectiveDateTime: string
  expirationDateTime: string
  createdAt: string
}

