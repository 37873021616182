import "./SubscriptionsTableItem.scss";
import {ISubscription, PlanEnum} from "@/interfaces/subscriptions-interfaces";
import dayjs from "dayjs";
import {FormSelect} from "@/shared/Form/FormSelect/FormSelect";
import React, {useContext} from "react";
import {ModalContext} from "@/contexts";
import {ChangeSubscriptionModal} from "@/components/Modals/ChangeSubscriptionModal/ChangeSubscriptionModal";
import {selectOptions} from "@/components/Subscriptions/Subscriptions";
import {
  SuperadminSubscriptionInfoModal
} from "@/components/Modals/SuperadminSubscriptionInfoModal/SuperadminSubscriptionInfoModal";

type Props = {
  subscription: ISubscription;
  handleSubscriptionChange: (orgId: string, subscriptionLevel: PlanEnum, expirationDate?: Date) => Promise<void>;
  onClick?: () => void
};

export function SubscriptionsTableItem({subscription, handleSubscriptionChange, onClick}: Props) {
  const {openModal} = useContext(ModalContext);

  const {numberOfCoaches, numberOfAthletePins, name, pin, numberOfTeams, subscriptionInfo, id} = subscription;
  const handleOnChange = async (plan: string | number) => {
    handleOpenChangeSubscriptionModal(plan as PlanEnum)
  }

  const handleOpenChangeSubscriptionModal = (plan: PlanEnum) => {
    // openModal(<ChangeSubscriptionModal subscription={subscription} changeTo={plan}
    //                                    handleSubscriptionChange={handleSubscriptionChange}/>);
    openModal(<SuperadminSubscriptionInfoModal subscription={subscription} handleOrgPlanChange={handleSubscriptionChange} changeTo={plan}/>)
  }


  return (
    <div className="subscriptions-table-item" onClick={onClick}>
      <div className="name">{name}</div>
      <div className="name">{pin}</div>
      <div className="plan" onClick={(e)=>e.stopPropagation()}>
        <FormSelect
          isForm={false}
          options={selectOptions}
          onChange={handleOnChange}
          value={
            selectOptions.find(item => item.value === subscriptionInfo.subscriptionLevel)?.text
          }
        />
      </div>
      <div
        className="name">{subscriptionInfo.expirationDateTime ? dayjs(subscriptionInfo.expirationDateTime).format("MM/DD/YYYY") : '-'}
      </div>
      <div className="name">{numberOfTeams}</div>
      <div className="name">{numberOfCoaches}</div>
      <div className="name">{numberOfAthletePins}</div>
    </div>
  );
}
