import "./FormLabel.scss";
import {ReactNode} from "react";

interface FormLabelProps {
  children?: ReactNode;
  className?: string;
  extra?: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function FormLabel({
                            children,
                            className,
                            extra,
                            onClick,
                          }: FormLabelProps): JSX.Element {
  return (
    <div className={`form-label ${className}`} onClick={onClick}>
      <div className="form-label__text">{children}</div>

      {extra && <div className="form-label__extra">{extra}</div>}
    </div>
  );
}
