import Api from "@/api/api";
import {CustomerContext, ModalContext} from "@/contexts";
import {ICoachInTeam, ITeam} from "@/interfaces/interface";
import {Form, Modal} from "@/shared";
import {ChangeEvent, useContext, useState} from "react";
import {toast} from "react-toastify";
import "./CreateCoachModal.scss";

type FormErrors = {
  email?: string;
  name?: string;
};

type FormValueType = {
  email?: string;
  name: string;
};

type Props = {
  selectedTeam: ITeam;
  coach?: ICoachInTeam;
};

export function CreateCoachModal({selectedTeam}: Props) {
  const {closeModal} = useContext(ModalContext);
  const {getTeams} = useContext(CustomerContext);

  const {id: teamId} = selectedTeam || {};

  const [formError, setFormError] = useState<FormErrors>({});

  const [formValues, setFormValues] = useState<FormValueType>({
    email: "",
    name: "",
  });

  const [loading, setLoading] = useState(false);

  const {email, name} = formValues;

  const validate = () => {
    const errors: FormErrors = {};
    const requiredFields: Partial<keyof FormErrors>[] = [
      "name", "email"
    ];
    requiredFields.forEach((field) => {
      if (!formValues[field]) {
        errors[field] = "Required field";
      }
    });

    if (Object.keys(errors).length) {
      return errors;
    }

    if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(email)) {
      errors.email = "Invalid email address";
      return errors;
    }
  };

  const handleFormUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    const {value, name} = event.target;

    setFormValues({...formValues, [name]: value});
  };

  const handleDropdownChange = (name: string, value: string) => {
    setFormValues({...formValues, [name]: value});
  };
  const addCoach = async () => {
    const errors = validate();
    if (errors) {
      setFormError(errors);
      return;
    }

    try {
      setLoading(true);
      const res = await Api.post(
        `/v2/organization-admin/organizations/teams/${teamId}/coaches`,
        formValues
      );
      if (res.status === 200) {
        await getTeams().then(() => {
          closeModal();
          closeModal();
        });
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Some error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal className="add-athlete-modal">
      <Modal.Header text="Add Coach"/>
      <Form className="add-athlete-modal__form">
        <Form.Row>
          <Form.Label>Name</Form.Label>
          <Form.Input
            value={name}
            onChange={handleFormUpdate}
            errorMessage={formError.name}
            name="name"
            placeholder="Enter name"
          />
        </Form.Row>
        <Form.Row>
          <Form.Label>Email</Form.Label>
          <Form.Input
            value={email}
            errorMessage={formError.email}
            onChange={handleFormUpdate}
            name="email"
            placeholder="example@gmail.com"
          />
        </Form.Row>
      </Form>

      <Modal.Footer>
        <Form.Button type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>
        <Form.Button loading={loading} onClick={addCoach}>
          ADD
        </Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
