import React, {useEffect, useState} from "react";
import {Amplify, Auth} from "aws-amplify";
import {CognitoUser, CognitoUserSession} from "amazon-cognito-identity-js";
import Api from "../api/api";
import {awsConfig} from "@/environments";
import {IMeResponse, UserRoleEnum} from "@/interfaces/interface";
import {toast} from "react-toastify";
import {UserSubscriptionInfo} from "@/interfaces/subscriptions-interfaces";

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export type AuthUser = CognitoUser & {
  signInUserSession: CognitoUserSession;
};

export interface IAuth {
  authStatus?: AuthStatus;

  signInWithEmail: (
    username: string,
    password: string,
    cb: () => void,
    newPassword?: string
  ) => Promise<void | string>;
  signOut?: any;
  isSignedIn: boolean;
  getUser: () => Promise<void>;
  userRole: UserRoleEnum | null;
  setUserRole: (role: UserRoleEnum | null) => void;
  userName: string;
  availableRoles: UserRoleEnum[] | null;
  subscriptionInfo: UserSubscriptionInfo | null;
}

type Props = {
  children?: React.ReactNode;
};

Amplify.configure(awsConfig);

const AuthContext = React.createContext({} as IAuth);

const AuthContextProvider = ({children}: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const isSignedIn = authStatus === AuthStatus.SignedIn;
  const [userRole, setUserRole] = useState<UserRoleEnum | null>(null);
  const [userName, setUserName] = useState<string>("");
  const [availableRoles, setAvailableRoles] = useState<UserRoleEnum[] | null>(
    null
  );
  const [subscriptionInfo, setSubscriptionInfo] = useState<UserSubscriptionInfo | null>(null)

  useEffect(() => {
    const init = async () => {
      try {
        await Auth.currentSession();

        const user = await Auth.currentAuthenticatedUser();

        if (user) {
          await getUser();
        }
      } catch (error) {
        setAuthStatus(AuthStatus.SignedOut);
      }
    };

    init();
  }, []);

  async function signInWithEmail(
    username: string,
    password: string,
    cb: () => void,
    newPassword?: string
  ) {
    try {
      const user: AuthUser | null = await Auth.signIn(username, password);
      if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
        if (newPassword) {
          await Auth.completeNewPassword(user, newPassword).then(async () => {
            cb();
            await getUser();
          });
        }

        return "NEW_PASSWORD_REQUIRED";
      }

      if (user) {
        cb();
        await getUser();
      }
    } catch (err: any) {
      toast.error(`${err.message}`);
      setAuthStatus(AuthStatus.SignedOut);
      return err;
    }
  }

  const getUser = async () => {
    try {
      const response = await Api.get<IMeResponse>("/admin/users/me");

      if (response.data) {
        setAuthStatus(AuthStatus.SignedIn);
        setUserRole(response.data.dashboardRole);
        setUserName(response.data.name);
        setAvailableRoles(response.data.roles);
        setSubscriptionInfo(response.data.subscriptionInfo)
      }
    } catch (error: any) {
      toast.error(`${error.response.data.message}`);
      // signOut()
    } finally {
    }
  };

  function signOut() {
    Auth.signOut();
    setUserRole(null);
    setAuthStatus(AuthStatus.SignedOut);
  }

  const state = {
    authStatus,
    signInWithEmail,
    signOut,
    isSignedIn,
    getUser,
    userRole,
    userName,
    availableRoles,
    setUserRole,
    subscriptionInfo
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export {AuthContextProvider, AuthContext};
