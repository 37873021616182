import React, {useContext, useState} from "react";
import {Form, Modal} from "@/shared";
import {ModalContext,} from "@/contexts";
import "./ChangeSubscriptionModal.scss";
import {ISubscription, PlanEnum, PlanMappingEnum,} from "@/interfaces/subscriptions-interfaces";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {getLeftDays} from "@/helpers/getLeftDays";

dayjs.extend(relativeTime);


interface Props {
  subscription: ISubscription;
  changeTo?: PlanEnum;
  handleSubscriptionChange: (orgId: string, subscriptionLevel?: PlanEnum, expirationDate?: Date) => Promise<void>;
}

export function ChangeSubscriptionModal({subscription, changeTo, handleSubscriptionChange}: Props) {
  const {subscriptionInfo, id, name} = subscription;
  const {subscriptionLevel, expirationDateTime, effectiveDateTime} = subscriptionInfo
  const [loading, setLoading] = useState(false);
  const {closeModal, openModal} = useContext(ModalContext);

  const handlePlanChange = async () => {
    setLoading(true);
    try {
     handleSubscriptionChange(id, changeTo )
      closeModal();
    } finally {
      setLoading(false);
    }
  };

  const listItems = [
    {
      title: 'Current plan:',
      primaryText: PlanMappingEnum[subscriptionLevel]
    },
    {
      title: 'Expire date:',
      primaryText: dayjs(expirationDateTime).format("MM/DD/YYYY"),
      subtext: expirationDateTime ? getLeftDays(expirationDateTime) : ''
    }
  ]

  return (
    <Modal className="change-subscription-modal">
      <Modal.Header className='change-subscription-modal__header'
                    text={`Are you sure you want to change subscription plan for ${subscription.name}?`}/>
      <div className='change-subscription-modal__list'>
        {listItems.map(({title, primaryText, subtext}, index) => {
            if (subscriptionLevel === PlanEnum.FREE_VERSION && index === 1) {
              return null
            } else {
              return (<div className='change-subscription-modal__list-item' key={index}>
                <>{title}</>
                <div className='change-subscription-modal__list-item__left'>
                  <span className='change-subscription-modal__list-item__left--primary'>{primaryText}</span>
                  {subtext && <span className='change-subscription-modal__list-item__left--subtext'>{subtext}</span>}
                </div>
              </div>)
            }
          }
        )}
      </div>
      <Modal.Footer>
        <Form.Button type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>
        <Form.Button loading={loading} onClick={handlePlanChange}>
          CHANGE
        </Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
