import "./SubscriptionRequestsTableItem.scss";
import {SubscriptionRequest} from "@/interfaces/subscriptions-interfaces";
import dayjs from "dayjs";
import React, {useContext} from "react";
import {Form, ImageFromAssets} from "@/shared";
import {ChangeSubscriptionModal} from "@/components/Modals/ChangeSubscriptionModal/ChangeSubscriptionModal";
import {ModalContext} from "@/contexts";
import {selectOptions} from "@/components";

type Props = {
  subscription: SubscriptionRequest;
  handleSubscriptionApprove: (orgId: string) => Promise<void>;
  handleSubscriptionDecline: (orgId: string) => Promise<void>;

};

export function SubscriptionRequestsTableItem({
                                                subscription,
                                                handleSubscriptionApprove,
                                                handleSubscriptionDecline
                                              }: Props) {
  const {openModal} = useContext(ModalContext);
  const {
    numberOfCoaches,
    numberOfAthletePins,
    name,
    numberOfTeams,
    subscriptionInfo,
    id,
    changeSubscriptionRequest, pin
  } = subscription;

  const handleApproveRequestModal = () => {
    openModal(<ChangeSubscriptionModal subscription={subscription}
                                       handleSubscriptionChange={() => handleSubscriptionApprove(id)}/>);
  }

  return (
    <div className="subscriptions-request-table-item">
      <div className="name">{name}</div>
      <div className="name">{pin}</div>
      <div className="request-plan">
        {selectOptions.find(item => item.value === changeSubscriptionRequest.subscriptionLevel)?.text}
      </div>
      <div
        className="name">{subscriptionInfo.expirationDateTime ? dayjs(subscriptionInfo.expirationDateTime).format("MM/DD/YYYY") : '-'}
      </div>
      <div className="name">{numberOfTeams}</div>
      <div className="name">{numberOfCoaches}</div>
      <div className="name">{numberOfAthletePins}</div>
      <div className="actions-column">
        <Form.Button
          onClick={() => handleApproveRequestModal()}
          className="approve-button"
        >
          <ImageFromAssets name="check-icon-black.svg"/>
        </Form.Button>
        <Form.Button
          onClick={() => handleSubscriptionDecline(id)}
          className="decline-button"
        >
          <ImageFromAssets name="close-icon.svg"/>
        </Form.Button>
      </div>
    </div>
  );
}
