import {ReactNode, useContext} from "react";
import "./Modal.scss";
import {ModalFooter} from "./ModalFooter/ModalFooter";
import {ModalHeader} from "./ModalHeader/ModalHeader";
import {useOnClickOutside} from "@/hooks";
import {ModalContext} from "@/contexts";

interface ModalProps {
  children: ReactNode;
  className?: string;
  innerRef?: any;
}

export const Modal = ({children, className, innerRef}: ModalProps) => {
  const {closeModal} = useContext(ModalContext);

  useOnClickOutside(innerRef, () => {
    closeModal()
  });

  return (
    <div ref={innerRef} className={`modal ${className}`}>
      {children}
    </div>
  );
};

Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;
