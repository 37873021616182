export const getDistanceInNeededUnits = ({
                                           distance,
                                           distanceUnits,
                                         }: {
  distance: number;
  distanceUnits: "meters" | "yards";
}): number => {
  if (distanceUnits === "meters") {
    //meters per second
    return +distance.toFixed(1);
  } else {
    //yards per second
    return +(distance / 0.9144).toFixed(1);
  }
};
