import React, {useContext, useMemo, useState} from "react";
import {Coach, EditCoachForm} from "@/interfaces/interface";
import {Form, Modal} from "@/shared";
import {AuthContext, CoachContext, CustomerContext, ModalContext,} from "@/contexts";
import {toast} from "react-toastify";
import "./EditCoachModal.scss";
import Api from "@/api/api";
import {MakeCoachAnOrgAdmin} from "@/components/Modals/MakeCoachAnOrgAdmin/MakeCoachAnOrgAdmin";


interface Props {
  coach: Coach;
  selectedOrganizationID: string;
  updateClients: () => Promise<void>;
}

type FormErrors = {
  email?: string;
  name?: string;
  role?: string;
};

export function EditCoachModal({coach, selectedOrganizationID, updateClients}: Props) {
  const [formValues, setFormValues] = useState<EditCoachForm>(
    new EditCoachForm(coach)
  );
  const [formError, setFormError] = useState<FormErrors>({});
  const [loading, setLoading] = useState(false);
  const {closeModal, openModal} = useContext(ModalContext);
  const {userRole} = useContext(AuthContext);
  const {getTeams} = useContext(CoachContext);
  const {getTeams: getCustomerTeams} = useContext(CustomerContext);

  const isOrgAdmin = coach.role === 'Organization Admin';

  const isFormChanged = useMemo(() => {
    return coach.email !== formValues.email || coach.name !== formValues.name
  }, [coach, formValues])


  const {email, name, role} = formValues;

  const handleFormUpdate = (
    property: string,
    value: string | number | null | undefined
  ) => {
    setFormValues({...formValues, [property]: value});
  };

  const validate = () => {
    const errors: FormErrors = {};
    const requiredFields: Partial<keyof FormErrors>[] = [
      "email",
      "name",
      "role",
    ];
    requiredFields.forEach((field) => {
      if (!formValues[field]) {
        errors[field] = "Required field";
      }
    });

    if (Object.keys(errors).length) {
      return errors;
    }

    if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(email)) {
      errors.email = "Invalid email address";
      return errors;
    }
  };


  const handleCoachMakeOrgAdmin = async () => {
   const res =  await Api.patch(`/v2/admin/organizations/${selectedOrganizationID}/coaches/${coach.username}/promote`);
   if (res.status == 200) {
     toast.success(`${coach.name} is Org Admin`)
   }
    await updateClients();
    closeModal();
    closeModal();
  }

  const handleAthleteUpdate = async () => {
    const errors = validate();
    if (errors) {
      setFormError(errors);
      return;
    }
    setFormError({});
    setLoading(true);
    try {
      if (coach.role !== formValues.role && formValues.role === 'Organization Admin') {
        handleHardAthleteDelete()
      }
      if (isFormChanged) {
        const newData = {
          email: formValues.email,
          name: formValues.name,
        }
        const res = await Api.put(`/admin/users/${coach.username}/`, newData )
        if (res.status == 200) {
          toast.success(`${coach.name} is updated`)

        }
        await updateClients();
        closeModal();
      }

    } catch (error: any) {
      const {message} = error?.response?.data;
      if (message) {
        toast.error(message);
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  const selectRoleConfig = isOrgAdmin ? {
    selectIcon: 'arrow-down-transparent.svg'
  } : {}

  const handleHardAthleteDelete = () => {
    openModal(<MakeCoachAnOrgAdmin loading={loading} handleCoachMakeOrgAdmin={handleCoachMakeOrgAdmin} coach={coach}/>);
  }

  return (
    <Modal className="edit-athlete-modal">
      <Modal.Header text="Edit Coach"/>
      <Form className="edit-athlete-modal__form">
        <Form.Row>
          <Form.Label>Email</Form.Label>
          <Form.Input
            value={formValues.email}
            onChange={({target}) => handleFormUpdate("email", target.value)}
            errorMessage={formError.email}
            placeholder="example@mail.com"
          />
        </Form.Row>
        <Form.Row>
          <Form.Label>Name</Form.Label>
          <Form.Input
            value={name}
            onChange={({target}) => handleFormUpdate("name", target.value)}
            errorMessage={formError.name}
            placeholder="Enter name"
          />
        </Form.Row>

        <Form.Row>
          <Form.Label>Role</Form.Label>
          <Form.Select
            {...selectRoleConfig}
            disabled={isOrgAdmin}
            value={role}
            onChange={(gender) => handleFormUpdate("role", gender)}
            options={[
              {text: "Coach", value: "Coach"},
              {text: "Organization Admin", value: "Organization Admin"},
            ]}
            errorMessage={formError.role}
          />
        </Form.Row>
      </Form>
      <Modal.Footer>
        <Form.Button type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>
        <Form.Button loading={loading} onClick={handleAthleteUpdate}>
          UPDATE COACH
        </Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
