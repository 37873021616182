import Api from "@/api/api";
import {CustomerContext, ModalContext} from "@/contexts";
import {Form} from "@/shared";
import {Modal} from "@/shared/Modal/Modal";
import {useContext, useState} from "react";
import "./EditTeamModal.scss";
import {toast} from "react-toastify";
import {ITeam} from "@/interfaces/interface";

type Props = {
  organization: ITeam;
};

type FormValue = {
  name: string;
  address: string;
  billingAddress: string;
};

const initialState = {
  name: "",
  address: "",
  billingAddress: "",
};

export function EditTeamModal({organization}: Props) {
  const {closeModal} = useContext(ModalContext);
  const {getTeams} = useContext(CustomerContext);

  const [formValue, setFormValue] = useState<FormValue>({
    name: organization.name,
    address: organization.address,
    billingAddress: organization.billingAddress,
  });

  const [formError, setFormError] = useState<FormValue>(initialState);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const {name, address, billingAddress} = formValue;

  const validate = () => {
    let error = "";

    if (name?.length < 5) {
      error = "Team Name should be longer than 4 symbols";

      setFormError({
        ...initialState,
        name: error,
      });

      return error;
    }
    if (address?.length < 5) {
      error = "Address should be longer than 4 symbols";

      setFormError({
        ...initialState,
        address: error,
      });

      return error;
    }
    if (billingAddress?.length < 5) {
      error = "Billing address should be longer than 4 symbols";

      setFormError({
        ...initialState,
        billingAddress: error,
      });

      return error;
    }
  };

  const handleConfirm = async () => {
    const error = validate();
    if (error) {
      toast.error(error);
      return;
    }

    const res = await Api.put(
      `admin/customers/organizations/${organization.id}`,
      formValue
    );
    if (res.status === 200) {
      await getTeams().then(() => closeModal());
    }
  };

  return (
    <Modal>
      <Modal.Header text="Edit Team"/>

      <Form.Row>
        <Form.Label>Team Name</Form.Label>
        <Form.Input
          errorMessage={formError.name}
          onChange={handleChange}
          value={name}
          name={"name"}
        />
      </Form.Row>
      <Modal.Footer>
        <Form.Button type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>

        <Form.Button onClick={handleConfirm}>SAVE</Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
