import {ICoachAthletesRequests, UserStatusEnum,} from "@/interfaces/interface";
import {ImageFromAssets} from "@/shared";
import {RequestBodyApproved} from "./RequestBodyApproved/RequestBodyApproved";
import {RequestBodyAutoApproved} from "./RequestBodyAutoApproved/RequestBodyAutoApproved";
import {RequestBodyDeclined} from "./RequestBodyDeclined/RequestBodyDeclined";
import {RequestBodyNew} from "./RequestBodyNew/RequestBodyNew";
import "./RequestsBody.scss";

type Props = {
  activeStatus: UserStatusEnum;
  athletesByStatus: ICoachAthletesRequests[];
};

export function RequestBody({activeStatus, athletesByStatus}: Props) {
  const bodyByStatus: Record<
    UserStatusEnum,
    | typeof RequestBodyApproved
    | typeof RequestBodyNew
    | typeof RequestBodyDeclined
    | typeof RequestBodyAutoApproved
  > = {
    [UserStatusEnum.APPROVED]: RequestBodyApproved,
    [UserStatusEnum.DECLINED]: RequestBodyDeclined,
    [UserStatusEnum.PENDING]: RequestBodyNew,
    [UserStatusEnum.AUTO_APPROVED]: RequestBodyAutoApproved,
  };

  const Body = bodyByStatus[activeStatus];

  if (Boolean(!athletesByStatus.length)) {
    return (
      <div className="empty-users">
        <ImageFromAssets
          className="empty-users__image"
          name="empty-users.webp"
        />
      </div>
    );
  }

  return <Body athletesByStatus={athletesByStatus}/>;
}
