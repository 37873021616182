import Api from "@/api/api";
import {AddAutoapprovedCoachModal} from "@/components";
import {CustomerContext, ModalContext} from "@/contexts";
import {ICoachAthletesRequests} from "@/interfaces/interface";
import {Form, ImageFromAssets, ItemContainer} from "@/shared/";
import {useContext, useState} from "react";
import "./AutoApproveRequestHeader.scss";

type Props = {
  athletesByStatus: ICoachAthletesRequests[];
};

export function AutoApproveRequestHeader({athletesByStatus}: Props) {
  const [loading, setLoading] = useState(false);

  const {openModal} = useContext(ModalContext);

  const {getCustomerData} = useContext(CustomerContext);

  const handleAddAutoApprovedCoach = () => {
    openModal(<AddAutoapprovedCoachModal/>);
  };
  const approveAllVisible = athletesByStatus.length > 0;

  const handleRemoveAllClick = async () => {
    try {
      setLoading(true);

      const res = await Api.patch(
        `/v2/organization2-admin/organization2s/teams/auto-approve-emails`
      );
      if (res.status === 200) {
        await getCustomerData();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <ItemContainer.Header
      className="new-request-header"
      leftSide={<div>Auto Approved Requests</div>}
      rightSide={
        <div className="actions">
          <Form.Button onClick={handleAddAutoApprovedCoach}>
            <ImageFromAssets name="user-approve.svg"/>
            ADD COACH
          </Form.Button>

          <Form.Button
            onClick={handleRemoveAllClick}
            hidden={!approveAllVisible}
            type="decline"
            loading={loading}
          >
            <ImageFromAssets name="user-decline.svg"/>
            REMOVE ALL
          </Form.Button>
        </div>
      }
    />
  );
}
