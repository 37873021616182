import Api from "@/api/api";
import {CustomerContext, ModalContext} from "@/contexts";
import {ITeam} from "@/interfaces/interface";
import {Form, Modal} from "@/shared";
import {useContext, useState} from "react";
import "./AddCoachModal.scss";

type Props = {
  selectedTeam: ITeam;
};

export function AddCoachModal({selectedTeam}: Props) {
  const {customerData, getTeams} = useContext(CustomerContext);

  const {coaches: teamCoaches, id: teamId} =
    selectedTeam;

  const {closeModal} = useContext(ModalContext);

  const [selectedCoachID, setSelectedCoachID] = useState<string>("");

  const [formError, setFormError] = useState("");

  const {coaches = []} = customerData || {};

  const [loading, setLoading] = useState(false);

  const uniqueAndApprovedCoaches = coaches.filter((approvedCoach) => {
    return !teamCoaches.some((orgCoach) => {
      return orgCoach.username === approvedCoach.username;
    });
  });

  const itemsForDropdown = uniqueAndApprovedCoaches.map((coach) => {
    return {
      text: coach.userData?.name || coach.userData?.email || "",
      value: coach.username,
    };
  });

  const addCoach = async () => {
    try {
      setLoading(true);

      if (!selectedCoachID) {
        setFormError("Required field");
      }

      const res = await Api.patch(
        `/v2/organization-admin/organizations/teams/${teamId}/coaches/add`,
        {
          coachUsername: selectedCoachID,
        }
      );
      if (res.status === 200) {
        await getTeams().then(() => closeModal());
        closeModal();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal>
      <Modal.Header text="Add Coach"/>

      <Form.Row>
        <Form.Label>Select Coach</Form.Label>
        <Form.Select
          value={selectedCoachID}
          errorMessage={formError}
          options={itemsForDropdown}
          placeholder="Select Coach"
          onChange={(val) => setSelectedCoachID(val.toString())}
        />
      </Form.Row>

      <Modal.Footer>
        <Form.Button type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>

        <Form.Button loading={loading} onClick={addCoach}>
          ADD
        </Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
