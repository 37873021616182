import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import {Overlay, Spinner} from "@shared";
import "react-toastify/dist/ReactToastify.css";
import {AuthContextProvider} from "./contexts";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Suspense
    fallback={
      <Overlay>
        <Spinner/>
      </Overlay>
    }
  >
    <AuthContextProvider>
      <App/>
    </AuthContextProvider>
  </Suspense>
  // </React.StrictMode>
);
