import Api from "@/api/api";
import {Assessment} from "@/components";
import {AuthContext, ModalContext} from "@/contexts";
import {GearsEnum, IAssessment, IAssessmentResponse, ISelectOption,} from "@/interfaces/interface";
import {EmptyContainer, Form, ItemContainer, Modal, Tabs} from "@/shared";
import {useContext, useEffect, useState} from "react";
import "./AthleteAssessmentsModal.scss";

type Props = {
  athleteName?: string;
  athletePIN: string;
  orgPIN: string;
};

export function AthleteAssessmentsModal({
                                          orgPIN,
                                          athletePIN,
                                          athleteName,
                                        }: Props) {

  const {closeModal} = useContext(ModalContext);
  const {userRole} = useContext(AuthContext);
  const isCoach = userRole === "ROLE_COACH";

  const [selectedGear, setSelectedGear] = useState<any>('GEAR_2');
  const [assessments, setAssessments] = useState<IAssessment[]>([]);

  const organizationPIN = orgPIN;

  useEffect(() => {
    const init = async () => {
      try {
        const response = await Api.post<IAssessmentResponse>(
          isCoach ?
            `v2/coach/organizations/teams/${organizationPIN}/athletes/${athletePIN}/assessments/search?gear=${selectedGear}` :
            `/v2/organization-admin/organizations/teams/${organizationPIN}/athletes/${athletePIN}/assessments/search?gear=${selectedGear}`
        );
        if (response.status === 200) {
          setAssessments(
            response.data.data.filter(
              (assessment) => assessment.runs.length > 0
            )
          );
        }
      } catch (error) {
      } finally {
      }
    };
    init();
  }, [athletePIN, organizationPIN, selectedGear]);

  const gears: ISelectOption[] = Object.entries(GearsEnum).map((val) => ({
    text: val[1] || "",
    value: val[0] || "",
  }));

  const onTabClick = (tabIndex: number) => {
    setSelectedGear(Object.keys(GearsEnum)[tabIndex]);
  };

  const assessmentExists = Boolean(assessments.length);

  return (
    <Modal className="athlete-assessments-modal">
      <Modal.Header text={athleteName ? `${athleteName}'s Assessments` : 'Assessments'}/>

      <Form.Row>
        <Tabs
          activeTab={selectedGear}
          tabs={gears}
          onTabClick={onTabClick}
          className="athlete-assessments-modal__gears"
        />
      </Form.Row>

      <ItemContainer className="athlete-assessments-modal__assessments">
        {!assessmentExists && (
          <EmptyContainer
            imageName="empty-users.webp"
            text={`There are no Assessments in ${selectedGear}`}
          />
        )}

        {assessmentExists &&
          assessments.map((assessment, index) => {
            const {id} = assessment;

            return (
              <Assessment
                key={index + id}
                assessment={assessment}
                assessmentTitle={
                  index === 0
                    ? "Baseline Assessment"
                    : `Assessment  ${index + 1}`
                }
              />
            );
          })}
      </ItemContainer>

      <Modal.Footer>
        <Form.Button type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>

        {/* <Form.Button loading={loading} onClick={addCoach}>
          ADD
        </Form.Button> */}
      </Modal.Footer>
    </Modal>
  );
}
