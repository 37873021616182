import {ISelectOption} from "@/interfaces/interface";
import React from "react";
import "./Tabs.scss";
import {ItemContainer} from "@/shared";

interface TabsProps {
  tabs: ISelectOption[];
  className?: string;
  activeTab: string;
  onTabClick: (index: number) => void;
  loading?: boolean;
}

export function Tabs({
                       tabs,
                       activeTab,
                       onTabClick,
                       className,
                       loading,
                     }: TabsProps) {
  return (
    <ItemContainer
      className={`picker-tabs ${className}`}
    >
      {tabs.map((tab, index) => {
        const isActive = tab.value === activeTab;

        return (
          <div
            className={`tab-item ${isActive && "tab-item--active"} ${
              loading && "tab-item--loading"
            }`}
            key={tab.value}
            onClick={() => onTabClick(index)}
          >
            {tab.text.toUpperCase()}
          </div>
        );
      })}
    </ItemContainer>
  );
}