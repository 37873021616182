import {Overlay} from "@/shared";
import {createContext, ReactNode} from "react";
import {useModal} from "../hooks";

interface IModalContext {
  isModalVisible: boolean;
  openModal: (content: JSX.Element) => void;
  closeModal: () => void;
}

const ModalContext = createContext({} as IModalContext);

type Props = {
  children?: ReactNode;
};

const ModalContextProvider = ({children}: Props) => {
  const {isModalVisible, modalContent, openModal, closeModal} = useModal();

  return (
    <ModalContext.Provider value={{openModal, closeModal, isModalVisible}}>
      {Boolean(modalContent) && <Overlay>{modalContent}</Overlay>}
      {children}
    </ModalContext.Provider>
  );
};

export {ModalContext, ModalContextProvider};
