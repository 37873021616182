import "./FormCheckbox.scss";

import {ChangeEventHandler} from "react";

interface FormCheckboxProps {
  value?: string | number | readonly string[];
  disabled?: boolean;
  name?: string;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  error?: React.ReactNode;
  checked?: boolean;
}

export function FormCheckbox({
                               value,
                               disabled,
                               name,
                               className,
                               onChange,
                               error,
                               checked = true,
                             }: FormCheckboxProps): JSX.Element {
  return (
    <div className="checkbox-wrapper">
      <label>
        <input
          className={checked ? "checked" : ""}
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
      </label>
    </div>
  );
}
