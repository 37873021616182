import {CustomerContext, ModalContext} from "@/contexts";
import {downloadExcelFile,} from "@/helpers/download-file";
import {ICoachOrganizationData} from "@/interfaces/interface";
import {ImageFromAssets} from "@/shared";
import dayjs from "dayjs";
import {useContext} from "react";
import "./CoachTeamTableItem.scss";

type Props = {
  onClick: () => void;
  isSelected: boolean;
  organization: ICoachOrganizationData;
};

export function CoachTeamTableItem({
                                     isSelected,
                                     organization,
                                     onClick,
                                   }: Props) {
  const {openModal} = useContext(ModalContext);

  const {getTeams} = useContext(CustomerContext);

  const {name, pin, id} = organization || {};

  // const handleEditOrganization = () => {
  //   openModal(<EditTeamModal organization={organization} />);
  // };
  // const handleOpenInfoOrganization = () => {
  //   openModal(<InfoTeamModal organization={organization} />);
  // };

  const handleExportExcel = async () => {
    try {
      await downloadExcelFile(
        `/v2/coach/organizations/teams/${id}/athletes/reports/excel`,
        `Team: ${name} ${dayjs().format("lll")}.xlsx`
      );
    } catch (e) {
    }
  };

  return (
    <div
      onClick={onClick}
      className={`coach-organization-table-item ${
        isSelected && "organization-table-item--active"
      }`}
    >
      <div className="name">{name}</div>
      <div className="pin">{pin}</div>

      <div className="actions">
        <ImageFromAssets onClick={handleExportExcel} name="actions-excel.svg"/>
        {/* {/* {/* <ImageFromAssets
          onClick={handleOpenInfoOrganization}
          name="actions-info.svg" */}
      </div>
    </div>
  );
}
