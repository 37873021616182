import {ISelectOption} from "@/interfaces/interface";
import React from "react";
import {ItemContainer} from "../ItemContainer/ItemContainer";
import "./Tabs.scss";

interface TabsProps {
  tabs: ISelectOption[];
  className?: string;
  activeTab: string;
  onTabClick: (index: number) => void;
  loading?: boolean;
}

export function Tabs({
                       tabs,
                       activeTab,
                       onTabClick,
                       className,
                       loading,
                     }: TabsProps) {
  return (
    <ItemContainer
      className={`tabs ${className}`}
      // style={[CONTAINER, style]}
    >
      {tabs.map((tab, index) => {
        const isActive = tab.value === activeTab;

        return (
          <div
            className={`tab-item ${isActive && "tab-item--active"} ${
              loading && "tab-item--loading"
            }`}
            key={tab.value}
            // style={isActive ? ITEM_ACTIVE : ITEM}
            onClick={() => onTabClick(index)}
          >
            {tab.text}
          </div>
        );
      })}
    </ItemContainer>
  );
}

// const CONTAINER: ViewStyle = { borderRadius: 4, padding: 5 };

// const ITEM: ViewStyle = {
//   borderRadius: 4,
//   justifyContent: 'center',
//   paddingVertical: spacing.small,
//   alignItems: 'center',
//   flex: 1,
// };

// const ITEM_ACTIVE: ViewStyle = {
//   ...ITEM,
//   backgroundColor: palette.springGreen,
// };

// const TAB_TEXT: TextStyle = {
//   fontFamily: fonts.futura400,
//   fontSize: 14,
//   lineHeight: 18,
//   color: palette.white,
// };

// const TAB_TEXT_ACTIVE: TextStyle = {
//   ...TAB_TEXT,
//   color: palette.black,
// };
