import Api from "@/api/api";
import {ClientInfoModal, EditClientModal} from "@/components";
import {SuperAdminTeamsInfo} from "@/components/Modals/SuperAdminTeamsInfo/SuperAdminTeamsInfo";
import {ModalContext} from "@/contexts";
import {IClient} from "@/interfaces/interface";
import {Form, ImageFromAssets} from "@/shared";
import {useContext} from "react";
import "./ClientManagementActiveUsers.scss";
import {EmptyItemContainer} from "@/shared/EmptyItemContainer/EmptyItemContainer";

type Props = {
  clients: IClient[];
  getClients: () => Promise<void>;
};

export function ClientManagementActiveUsers({clients, getClients}: Props) {
  const {openModal} = useContext(ModalContext);

  if (Boolean(!clients?.length)) {
    return (
      <div style={{height: 'calc(100% - 110px)'}}>
        <EmptyItemContainer text='You do not have added admin yet'/>
      </div>
    );
  }

  const handleArchiveClick = async (customerId: string) => {
    const res = await Api.patch(`/v2/admin/organizations/${customerId}/archive`);
    if (res.status === 200) {
      await getClients();
    }
  };

  const handleOpenOrganizationList = (id: string) => {
    const neededClient = clients.find((client) => client.id === id);
    if (neededClient) {
      openModal(<SuperAdminTeamsInfo client={neededClient}/>);
    }
  };

  const handleOpenClientEdit = (client: IClient) => {
    openModal(<EditClientModal getClients={getClients} client={client}/>);
  };

  const handleOpenClientInfo = (client: IClient) => {
    openModal(<ClientInfoModal getClients={getClients} client={client}/>);
  };

  return (
    <div className="client-management-active-users">
      {clients.map((client, index) => {
        const {admin, adminUsername, name, pin, id} = client;
        const {email, name: clientName} = admin || {};
        // const { email = "", name = "Unknown name" } = userData || {};

        return (
          <div key={id} className="client-management-active-users__item">
            <div className="client-management-active-users__item__text">
              {name}
            </div>

            <div className="client-management-active-users__item__text">
              {email}
            </div>
            <div className="client-management-active-users__item__text">
              {pin}
            </div>

            <div className="client-management-active-users__item__actions">
              <ImageFromAssets
                className="info-button"
                name="actions-info.svg"
                onClick={() => handleOpenClientInfo(client)}
              />

              <ImageFromAssets
                className="info-button"
                name="actions-edit.svg"
                onClick={() => handleOpenClientEdit(client)}
              />

              <Form.Button
                type="decline"
                onClick={() => handleArchiveClick(id)}
              >
                <ImageFromAssets name="user-decline.svg"/>
              </Form.Button>
            </div>
          </div>
        );
      })}
    </div>
  );
}
