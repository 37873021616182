import React, {useState} from "react";
import Api from "../api/api";
import {ICoachAthletesRequests, ICoachOrganizationData,} from "@/interfaces/interface";

export interface ICoachContext {
  getAthletesRequests: () => Promise<void>;
  athletesRequests: ICoachAthletesRequests[];
  getTeams: () => Promise<void>;
  teams: ICoachOrganizationData[];
}

type Props = {
  children?: React.ReactNode;
};

const CoachContext = React.createContext({} as ICoachContext);

const CoachContextProvider = ({children}: Props) => {
  const [athletesRequests, setAthletesRequests] = useState<
    ICoachAthletesRequests[]
  >([]);

  const [teams, setTeams] = useState<ICoachOrganizationData[]>(
    []
  );

  const getAthletesRequests = async () => {
    const res = await Api.get<ICoachAthletesRequests[]>(
      "/v2/coach/organizations/teams/athletes/requests"
    );

    if (res.status === 200) {
      setAthletesRequests(res.data);
    }
  };

  const getTeams = async () => {
    const res = await Api.get<ICoachOrganizationData[]>(
      "/v2/coach/organizations/teams"
    );

    if (res.status === 200) {
      setTeams(res.data);
    }
  };

  const state = {
    getAthletesRequests,
    athletesRequests,
    getTeams,
    teams,
  };
  return (
    <CoachContext.Provider value={state}>{children}</CoachContext.Provider>
  );
};

export {CoachContextProvider, CoachContext};
