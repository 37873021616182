import Api from "@/api/api";
import {CustomerContext, ModalContext} from "@/contexts";
import {IGetOrganizationResponse, ITeam,} from "@/interfaces/interface";
import {Form, Modal} from "@/shared";
import {useContext, useEffect, useState} from "react";
import "./AddAutoapprovedCoachModal.scss";

type FormErrorsType = {
  email?: string;
  selectedTeamID?: string;
};

type FormValueType = {
  email: string;
  selectedTeamID: string;
};

export function AddAutoapprovedCoachModal() {
  const {getCustomerData} = useContext(CustomerContext);

  const {closeModal} = useContext(ModalContext);

  const [organizations, setOrganizations] = useState<ITeam[]>([]);

  const [loading, setLoading] = useState(false);

  const getTeams = async () => {
    const res = await Api.get<IGetOrganizationResponse>(
      "v2/organization-admin/organizations/teams"
    );

    if (res.status === 200) {
      setOrganizations(res.data.data);
    }
  };

  useEffect(() => {
    const init = async () => {
      await getTeams();
    };
    init();
  }, []);

  const [formError, setFormError] = useState<FormErrorsType>({});

  const [formValue, setFormValue] = useState<FormValueType>({
    email: "",
    selectedTeamID: "",
  });

  const {email, selectedTeamID} = formValue;

  const itemsForDropdown = organizations.map((organization) => {
    return {
      text: organization.name,
      value: organization.id,
    };
  });

  const validate = () => {
    const errors: FormErrorsType = {};
    const requiredFields: Partial<keyof FormErrorsType>[] = [
      "email",
      "selectedTeamID",
    ];
    requiredFields.forEach((field) => {
      if (!formValue[field]) {
        errors[field] = "Required field";
      }
    });

    if (Object.keys(errors).length) {
      return errors;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(email)) {
      errors.email = "Invalid email address";
      return errors;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;

    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleAddCoach = async () => {
    try {
      setLoading(true);

      const errors = validate();
      if (errors) {
        setFormError(errors);
        return;
      }

      const res = await Api.post(
        `/v2/organization-admin/organizations/teams/${selectedTeamID}/auto-approve-emails?email=${email}`
      );
      if (res.status === 200) {
        await getCustomerData().then(() => closeModal());
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal>
      <Modal.Header text="Add Coach"/>

      <Form.Row>
        <Form.Label>Select Team</Form.Label>
        <Form.Select
          options={itemsForDropdown}
          value={selectedTeamID}
          placeholder="Select Team"
          errorMessage={formError.selectedTeamID}
          onChange={(val) =>
            setFormValue((prev) => ({
              ...prev,
              selectedTeamID: val.toString(),
            }))
          }
        />
      </Form.Row>

      <Form.Row>
        <Form.Label>Email address</Form.Label>
        <Form.Input
          name="email"
          placeholder="example@gmail.com"
          value={email}
          errorMessage={formError.email}
          onChange={handleChange}
        />
      </Form.Row>

      <Modal.Footer>
        <Form.Button type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>

        <Form.Button onClick={handleAddCoach}>ADD</Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
