import {Form} from "@/shared";
import {Modal} from "@/shared/Modal/Modal";
import {useContext, useState} from "react";
import "./InfoTeamModal.scss";
import {ITeam} from "@/interfaces/interface";
import {ModalContext} from "@/contexts";

type Props = {
  organization: ITeam;
};

type FormValue = {
  name: string;
  address: string;
  billingAddress: string;
};

export function InfoTeamModal({organization}: Props) {
  const {closeModal} = useContext(ModalContext);

  const [formValue] = useState<FormValue>({
    name: organization.name,
    address: organization.address,
    billingAddress: organization.billingAddress,
  });

  const {name, address, billingAddress} = formValue;

  return (
    <Modal>
      <Modal.Header text={organization.name}/>

      <Form.Row>
        <Form.Label>Team Name</Form.Label>
        <Form.Input disabled value={name} name={"name"}/>
      </Form.Row>
      <Modal.Footer>
        <Form.Button type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
