// import { IFile } from "@wsr/interfaces";
import {saveAs} from "file-saver";
import Api from "../api/api";

export const downloadFileByLink = (url: string, name: string) => {
  return fetch(url, {method: "GET"})
    .then((response: Response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return response.blob();
    })
    .then((blob) => saveAs(blob, name));
};

export const initDownloading = async (item: any, name: any): Promise<void> => {
  const response = await Api.get<string>(`file/${item.id}`);
  downloadFileByLink(response.data, name);
};

// export const downloadFile = (file: File): void => {
//   const link = document.createElement("a");
//   link.download = file.name;
//   link.href = window.URL.createObjectURL(file);
//   link.click();
// };

export const downloadExcelFile = async (
  url: string,
  name: string
): Promise<void> => {
  Api.get(url, {
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
  });
};

export const downloadFile = (data: BlobPart, fileName: string): void => {
  const parsedResponse = data;
  const blob = new Blob([parsedResponse], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"});
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
