import Api from "@/api/api";
import {SuperAdminTeamsInfo} from "@/components/Modals/SuperAdminTeamsInfo/SuperAdminTeamsInfo";
import {ModalContext} from "@/contexts";
import {IClient} from "@/interfaces/interface";
import {Form, ImageFromAssets} from "@/shared";
import {useContext} from "react";
import "./ClientManagementBlockedUsers.scss";
import {EmptyItemContainer} from "@/shared/EmptyItemContainer/EmptyItemContainer";

type Props = {
  clients: IClient[];
  getClients: () => Promise<void>;
};

export function ClientManagementBlockedUsers({clients, getClients}: Props) {
  const {openModal} = useContext(ModalContext);

  if (Boolean(!clients?.length)) {
    return (
      <div style={{height: 'calc(100% - 100px)'}}>
        <EmptyItemContainer text='You do not have blocked admin yet'/>
      </div>
    );
  }

  const handleRestoreClick = async (customerId: string) => {
    const res = await Api.patch(`/v2/admin/organizations/${customerId}/restore`);
    if (res.status === 200) {
      await getClients();
    }
  };

  const handleOpenOrganizationList = (id: string) => {
    const neededClient = clients.find((client) => client.id === id);
    if (neededClient) {
      openModal(<SuperAdminTeamsInfo client={neededClient}/>);
    }
  };

  return (
    <div className="client-management-blocked-users">
      {clients.map((client, index) => {
        const {admin, adminUsername, name, pin, id} = client;
        const {email, name: clientName} = admin || {};
        // const { email = "", name = "Unknown name" } = userData || {};

        return (
          <div key={id} className="client-management-blocked-users__item">
            <div className="client-management-blocked-users__item__text">
              {name}
            </div>

            <div className="client-management-blocked-users__item__text">
              {email}
            </div>
            <div className="client-management-blocked-users__item__text">
              {pin}
            </div>

            {/* <div className="name-column">
              <div>{clientName}</div>
              <div>{email}</div>
            </div> */}

            <div className="client-management-blocked-users__item__actions">
              {/* <ImageFromAssets
                className="info-button"
                name="actions-info.svg"
                onClick={() => handleOpenOrganizationList(id)}
              /> */}

              <Form.Button
                type="primary"
                onClick={() => handleRestoreClick(id)}
              >
                <ImageFromAssets name="user-approve.svg"/>
              </Form.Button>
            </div>
          </div>
        );
      })}
    </div>
  );
}
