import {CustomerContext} from "@/contexts";
import {SuperadminOrganization} from "@/interfaces/interface";
import {ImageFromAssets} from "@/shared";
import {useContext} from "react";
import "./SuperadminTeamTableItem.scss";
import {downloadExcelFile} from "@/helpers/download-file";
import dayjs from "dayjs";

type Props = {
  onClick: () => void;
  isSelected: boolean;
  team: SuperadminOrganization;
};

export function SuperadminTeamTableItem({
                                          isSelected,
                                          team,
                                          onClick,
                                        }: Props) {
  const {getTeams} = useContext(CustomerContext);

  const {id, name, pin} = team;

  const handleExportExcel = async () => {
    try {
      await downloadExcelFile(
        `/v2/admin/organizations/teams/${id}/reports/excel`,
        `Team: ${name} ${dayjs().format("lll")}.xlsx`
      );
    } catch (e) {
    }
  }

  return (
    <div
      onClick={onClick}
      className={`organization-table-item ${
        isSelected && "organization-table-item--active"
      }`}
    >
      <div className="name">{name}</div>
      <div className="pin">{pin}</div>

      <div className="actions">
        <ImageFromAssets onClick={handleExportExcel} name="actions-excel.svg"/>
      </div>
    </div>
  );
}
