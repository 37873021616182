import React, {ReactNode, useContext} from 'react';
import {Navigate} from 'react-router-dom';
import {AuthContext} from "@/contexts";
import {PlanEnum} from "@/interfaces/subscriptions-interfaces";

interface ProtectedRouteProps {
  children: ReactNode;
  deniedPlans?: PlanEnum[];
  redirectPath: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({children, deniedPlans, redirectPath, ...routeProps}) => {
  const {subscriptionInfo} = useContext(AuthContext);

  const hasNoPermission = deniedPlans && subscriptionInfo && deniedPlans.includes(subscriptionInfo?.subscriptionLevel);

  if (hasNoPermission) {
    return <Navigate to={redirectPath} replace/>;
  }

  return  <>{children}</>;
};

export default ProtectedRoute;
