import "./Overlay.scss";

import {ReactNode} from "react";

interface OverlayProps {
  children: ReactNode;
}

export const Overlay = ({children}: OverlayProps): JSX.Element => {
  return <div className="overlay">{children}</div>;
};
