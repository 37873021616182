import {ReactElement, ReactNode} from "react";
import {
  ChangeSubscriptionRequest,
  CustomerSubscriptionInfo,
  PlanEnum,
  UserSubscriptionInfo
} from "@/interfaces/subscriptions-interfaces";

export enum UserRoleEnum {
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_ORGANIZATION_ADMIN = "ROLE_ORGANIZATION_ADMIN",
  ROLE_COACH = "ROLE_COACH",
  ROLE_ATHLETE = "ROLE_ATHLETE",
}

export interface ISelectOption {
  text: string;
  value: string | number;
}

export interface IFormSelectOption {
  text: string | ReactElement;
  value: string | number;
}

export enum UserStatusEnum {
  PENDING = "PENDING",
  DECLINED = "DECLINED",
  APPROVED = "APPROVED",
  AUTO_APPROVED = "AUTO_APPROVED",
}

export interface IAutoApproveEmails {
  email: string;
  organizationId: string;
  timestamp: Date;
  teamId?: string;
  teamPin?: string;
}

export interface ICustomerMyResponse {
  id: string;
  name: string;
  contactInfo: string
  billingInfo: string
  autoApproveEmails: IAutoApproveEmails[];
  adminUsername: string;
  admin: IAdmin;
  coaches: ICoach[];
  athletes: IAthlete[];
  pin: string;
  changeSubscriptionRequest: ChangeSubscriptionRequest;
  previousChangeSubscriptionRequest: ChangeSubscriptionRequest | null;
  subscriptionInfo: CustomerSubscriptionInfo;
}

export interface IAdmin {
  username?: string;
  pin?: string;
  name?: string;
  email?: string;
  role?: string;
  userInfo?: IUserInfo;
  picture?: string;
}


export interface ICoach {
  username: string;
  userData?: IUser;
  status: UserStatusEnum;
  timestamp: Date;
  teamId?: string;
  teamPin?: string;
}

export interface IAthlete {
  pin: string;
  username: string;
  userData: IUser;
  status: string;
  timestamp: Date;
  isPinUser?: string;
}

export interface IUser {
  username?: string;
  pin?: string;
  name?: string;
  email?: string;
  role?: string;
  picture?: string;
  userInfo?: IAthleteUserInfo;
}


export interface ICoachInTeam {
  username: string
  name: string
  email: string
  dashboardRole: any
  roles: any[]
  userInfo: UserInfo
  picture: any
  role: any
}

export interface ITeam {
  id: string;
  name: string;
  address: string;
  billingAddress: string;
  pin: string;
  customerId: string;
  coaches: ICoachInTeam[];
  athletes: IAthlete[];
  approvedAthletes: IAthlete[];
}

export interface IGetOrganizationResponse {
  data: ITeam[];
  count: number;
}

export interface IMeResponse {
  username: string;
  pin?: null;
  name: string;
  email: string;
  subscriptionInfo: UserSubscriptionInfo;
  role: UserRoleEnum;
  roles: UserRoleEnum[];
  dashboardRole: UserRoleEnum;
  userInfo: IUserInfo;
  picture?: null;
}

export interface IUserInfo {
  gender?: null;
  weight?: null;
  weightUnit?: null;
  height?: null;
  heightUnit?: null;
  age?: null;
  phone?: null;
  signUpCustomerPin?: null;
  numberOfRuns: number;
  isApproved: boolean;
}

export interface IRoute {
  to: string;
  title: string;
  iconName: string;
  component: ReactNode;
  deniedPlans?: PlanEnum[]
}

//

export interface UserInfo {
  gender: string;
  weight: number;
  weightUnit: string;
  height: number;
  heightUnit: string;
  age: number;
  phone: string;
  signUpCustomerPin: string;
  numberOfRuns: number;
  isApproved: boolean;
  isEmailReal: boolean
  defaultOrganizationPin: any
}

export interface Admin {
  username: string;
  pin: string;
  name: string;
  email: string;
  role: string;
  userInfo: UserInfo;
  picture: string;
}

export interface IClient {
  id: string;
  name: string;
  adminUsername: string;
  admin: Admin;
  pin: string;
  isArchived: boolean;
  billingInfo: null | string;
  contactInfo: null | string;
  numberOfAthletes: number;
  numberOfCoaches: number;
  teams: SuperadminOrganization[];
}

export interface IGetClientsResponse {
  data: IClient[];
  count: number;
}

export interface IAthleteUserInfo {
  gender: string;
  weight: number;
  weightUnit: string;
  height: number;
  heightUnit: string;
  age: number;
  phone: string;
  signUpCustomerPin: string;
  signUpOrganizationPin: string;
  signUpCoachGroupPin: string;
  isApproved: boolean;
  isEmailReal: boolean;
}

export interface IAthleteUserData {
  username: string;
  pin: string;
  name: string;
  email: string;
  role: string;
  userInfo: IAthleteUserInfo;
  picture: string;
}

export interface ICoachAthletesRequests {
  username: string
  organizationPin: string;
  coachGroupPin: string;
  athletePin: string;
  userData: IAthleteUserData;
  status: string;
  timestamp: Date;
  teamPin: string
  teamId: string
}

export interface ICoachPin {
  teamName: string;
  organizationPin: string;
  teamPin: string;
}

export interface UserInfo2 {
  gender: string;
  weight: number;
  weightUnit: string;
  height: number;
  heightUnit: string;
  age: number;
  phone: string;
  signUpCustomerPin: string;
  signUpOrganizationPin: string;
  signUpCoachGroupPin: string;
  isApproved: boolean;
}

export interface UserData {
  username: string;
  pin: string;
  name: string;
  email: string;
  role: string;
  userInfo: UserInfo2;
  picture: string;
}

export interface Athlete {
  username: string;
  pin: string;
  teamPin?: string;
  userData: UserData;
  status: string;
  timestamp: Date;
}

export interface UserInfo3 {
  gender: string;
  weight: number;
  weightUnit: string;
  height: number;
  heightUnit: string;
  age: number;
  phone: string;
  signUpCustomerPin: string;
  signUpOrganizationPin: string;
  signUpCoachGroupPin: string;
  isApproved: boolean;
}

export interface Athlete2 {
  username: string;
  pin: string;
  name: string;
  email: string;
  role: string;
  userInfo: UserInfo3;
  picture: string;
}

export interface Team {
  id: string;
  name: string;
  athleteUsernames: string[];
  athletes: Athlete2[];
}

export interface Coach {
  id: string;
  coachUsername: string;
  pin: string;
  coach: IAthleteUserData;
  gear: string;
  numberOfRuns: number;
  organizationId: string;
  athletes: Athlete[];
  teams: Team[];
  name: string;
  email: string;
  role: string;
  username?: string;
}

export interface ICoachOrganizationData {
  id: string
  name: string
  pin: string
  organizationId: any
  organizationPin: any
  athletes: IAthlete[];
  approvedAthletes: IAthlete[];
}

export interface SuperadminOrganization {
  id: string
  name: string
  pin: string
  organizationId: any
  organizationPin: any


  address?: string;
  billingAddress?: string;
  customerId?: string;
  coaches?: ICoachInTeam[];
  athletes?: IAthlete[];
}

export interface IAssessmentResponse {
  data: IAssessment[];
  count: number;
}

export enum GearsEnum {
  GEAR_2 = "Gear 2",
  GEAR_2_PREP_W_BOUNDING = "Gear 2 Prep",
  GEAR_3 = "Gear 3",
  GEAR_3_PEAK_OR_HAMSTRING_PREP = "Gear 3 Peak",
  GEAR_4A = "Gear 4A",
  GEAR_4B = "Gear 4B",
  GEAR_4C = "Gear 4C",
}

export type GearKeys = keyof typeof GearsEnum;

export interface IAssessment {
  assessmentData?: any;
  id: string;
  athletePIN?: string;
  gear: GearKeys;
  runs: IRun[];
  nextRunNumber: number;
}

export interface IRun {
  id: string;
  number: number;
  topSpeed: number;
  distance: number;
  time: number;
  timeToTopSpeed: number;
  distanceUnit: "m" | "y";
  speedByTime: IChartItem[];
  timestamp: Date;
}

export interface IChartItem {
  speed: number;
  time: number;
}

export interface UserInfo2 {
  gender: string;
  weight: number;
  height: number;
  defaultOrganizationPin: string;
  isApproved: boolean;
}

export interface UserData2 {
  username: string;
  pin: string;
  name: string;
  email: string;
  dashboardRole: string;
  roles: string[];
  userInfo: UserInfo2;
  picture: string;
  role: string;
}

export interface Athlete3 {
  username: string;
  pin: string;
  userData: UserData2;
  status: string;
  timestamp: Date;
}

export class EditAthleteForm {
  email: string | undefined = "";
  name: string | undefined = "";
  gender: string | undefined = "";
  // age: number = 0;
  weight: number | undefined = 0;
  height: number | undefined = 0;

  // phoneNumber: string = "";

  constructor(athlete?: IAthleteUserData | IUser) {
    if (athlete) {
      this.email = athlete.email;
      this.name = athlete?.name;
      // this.age = athlete.userInfo?.age;
      this.gender = athlete.userInfo?.gender;
      this.height = athlete.userInfo?.height;
      // this.phoneNumber = athlete.userInfo?.phone;
      this.weight = athlete.userInfo?.weight;
    }
  }
}

export class EditCoachForm {
  email: string | undefined = "";
  name: string | undefined = "";
  role: string | undefined = "";
  constructor(coach?: Coach) {
    if (coach) {
      this.email = coach.email;
      this.name = coach?.name;
      this.role = coach?.role;
    }
  }
}
