import {ItemContainer} from "@shared";
import "./AdminAllTeams.scss";
import {SuperadminOrganization} from "@/interfaces/interface";
import {
  SuperadminTeamTableItem
} from "@/components/TableItems/TeamsTableItems/SuperadminTeamTableItem/SuperadminTeamTableItem";
import {EmptyItemContainer} from "@/shared/EmptyItemContainer/EmptyItemContainer";

type Props = {
  teams: SuperadminOrganization[];
  handleSelectTeam: (id: string) => void;
  selectedTeam?: string;
};

export function AdminAllTeams({
                                handleSelectTeam,
                                teams,
                                selectedTeam,
                              }: Props) {


  const organizationsExist = Boolean(teams.length);


  return (
    <ItemContainer className={`all-organizations`}>
      <ItemContainer.Header
        className="all-organizations__header"
        leftSide={<div>All Teams</div>}

      />

      <div className="all-organizations__body">
        {organizationsExist && (
          <div>
            {teams.map((team, index) => (
              <SuperadminTeamTableItem
                onClick={() => handleSelectTeam(team.id)}
                isSelected={selectedTeam === team.id}

                key={index}
                team={team}
              />
            ))}
          </div>
        )}
      </div>
      {!organizationsExist && (
        <div style={{height: 'calc(100% - 100px)'}}>
          <EmptyItemContainer text='You do not have added teams yet'/>
        </div>
      )}
    </ItemContainer>
  );
}
