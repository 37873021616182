import React, {useContext, useState} from "react";
import {Form, Modal} from "@/shared";
import {ModalContext,} from "@/contexts";
import "./SuperadminSubscriptionInfoModal.scss";
import {ISubscription, PlanEnum, PlanMappingEnum} from "@/interfaces/subscriptions-interfaces";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {FormSelect} from "@/shared/Form/FormSelect/FormSelect";
import {InfoListItem} from "@/shared/InfoListItem/InfoListItem";
import {toast} from "react-toastify";
import {getLeftDays} from "@/helpers/getLeftDays";
import DateInput from 'rsuite/DateInput';

// (Optional) Import component styles. If you are using Less, import the `index.less` file.
import 'rsuite/DateInput/styles/index.css';

dayjs.extend(relativeTime);

const selectOptions = Object.keys(PlanMappingEnum).map(key => ({
  text: PlanMappingEnum[key as keyof typeof PlanMappingEnum],
  value: PlanEnum[key as keyof typeof PlanEnum],
}));

interface Props {
  subscription: ISubscription;
  handleOrgPlanChange: (orgId: string, subscriptionLevel: PlanEnum, expirationDate?: Date) => Promise<void>;
  changeTo?: PlanEnum;
}


// TODO: Move this style to the SCSS file
const expireDateStyle = (error: boolean) => ({
  height: '52px',
  borderColor: error ? '#cc5951' : '#9999994D',
  backgroundColor: error ? 'rgba(220, 81, 72, 0.1)' : '#1c1c1c',
  borderRadius: '4px',
  color: 'white'
});

export function SuperadminSubscriptionInfoModal({subscription, handleOrgPlanChange, changeTo}: Props) {
  const {subscriptionInfo, id} = subscription;

  const subscriptionLevel = changeTo ? changeTo : subscriptionInfo?.subscriptionLevel
  const expirationDateTime = subscriptionInfo?.expirationDateTime

  const [loading, setLoading] = useState(false);
  const [newPlan, setNewPlan] = useState<PlanEnum | null>(subscriptionLevel)
  const [dateError, setDateError] = useState('')
  const [newExpireDate, setNewExpireDate] = useState<Date | null>(expirationDateTime ? new Date(expirationDateTime) : null)

  const {closeModal, openModal} = useContext(ModalContext);

  const handlePlanChange = async () => {
    await handleSubscriptionChange(newPlan as PlanEnum, newExpireDate as Date);
  };
  const handleSubscriptionChange = async (subscriptionLevel: PlanEnum, expirationDate?: Date) => {
    setLoading(true)
    try {
      await handleOrgPlanChange(id, subscriptionLevel, expirationDate);
      closeModal();
    } catch (error: any) {
      const {message} = error?.response?.data;
      if (message) {
        toast.error(message);
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setLoading(false)
    }
  }

  const listItems = [
    {
      title: 'Current plan:',
      primaryText: `${subscriptionLevel && PlanMappingEnum[subscriptionLevel]}`
    },
    {
      title: 'Expire date:',
      primaryText: dayjs(expirationDateTime).format("MM/DD/YYYY"),
      subtext: expirationDateTime ? getLeftDays(expirationDateTime) : ''
    }
  ]

  const handleOnPlanChange = (option: string | number) => {
    setNewPlan(option as PlanEnum)
  }
  function isValidDate(date?: Date|null) {
    return date instanceof Date && !isNaN(date.getTime());
  }
  const handleOnExpireDateChange = (value: Date | null) => {
    const minDate = new Date(); // Today
    const maxDate = new Date(2999, 11, 31); // December 31, 2999
    if (value && (value < minDate || value > maxDate) || !isValidDate(value)) {
      setDateError('Invalid date');
    } else {
      setDateError('')
    }
    setNewExpireDate(value);
  };



  const selectNewPlan = (
    <Form.Row>
      <Form.Label>Select New Plan</Form.Label>
      <FormSelect
        loading={loading}
        options={selectOptions}
        onChange={handleOnPlanChange}
        value={
          selectOptions.find(item => item.value === subscriptionLevel)?.text
        }
        selectIcon='arrow-down.svg'
      />
    </Form.Row>
  );


  return (
    <Modal className="subscription-info-modal">
      <Modal.Header
        className='subscription-info-modal__header'
        text='Subscription info'
      />
      <div className='subscription-info-modal__list'>
        {listItems.map(({title, primaryText, subtext}, index) => {
            if (subscriptionLevel === PlanEnum.FREE_VERSION && index === 1) {
              return null
            } else {
              return (
                <InfoListItem key={index} title={title} primaryText={primaryText} subtext={subtext}/>
              )
            }
          }
        )}
      </div>
      {selectNewPlan}
      <Form.Row>
        <Form.Label>Expire date</Form.Label>
        <DateInput
          format="MM/dd/yyyy"
          value={newExpireDate}
          style={expireDateStyle(Boolean(dateError))}
          onChange={handleOnExpireDateChange}
          // className={dateError && "form-input__input--error"}
          className={`form-input__input 
          ${(dateError) && "form-input__input--error"}
          `}
        />
        <div className="form-input__error-message">{dateError}</div>
      </Form.Row>
      <Modal.Footer>
        <Form.Button type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>
        <Form.Button loading={loading} onClick={handlePlanChange} disabled={Boolean(dateError)}>
          CHANGE
        </Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
