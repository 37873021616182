import {CoachContext, ModalContext} from "@/contexts";
import {ItemContainer} from "@shared";
import {useContext} from "react";
import "./CoachAllTeams.scss";
import {CoachTeamTableItem} from "@/components/TableItems/CoachTeamTableItem/CoachTeamTableItem";
import {EmptyItemContainer} from "@/shared/EmptyItemContainer/EmptyItemContainer";

type Props = {
  setSelectedTeamID: (id: string) => void;
  selectedTeamID: string;
};

export function CoachAllTeams({
                                setSelectedTeamID,
                                selectedTeamID,
                              }: Props) {
  const {openModal} = useContext(ModalContext);

  const {teams} = useContext(CoachContext);

  const teamsExist = Boolean(teams.length);

  return (
    <ItemContainer className={`all-organizations`}>
      <ItemContainer.Header
        className="all-organizations__header"
        leftSide={<div>All Teams</div>}
        rightSide={<></>}
      />

      <div className="all-organizations__body">
        {teamsExist && (
          <div>
            {teams.map((team, index) => (
              <CoachTeamTableItem
                onClick={() =>
                  setSelectedTeamID(team.id)
                }
                isSelected={
                  team.id === selectedTeamID
                }
                key={index}
                organization={team}
              />
            ))}
          </div>
        )}
      </div>
      {!teamsExist && (
        <div style={{height: 'calc(100% - 100px)'}}>
          <EmptyItemContainer text={'You do not have added teams yet'}/>
        </div>
      )}
    </ItemContainer>
  );
}
