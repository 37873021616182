export const getSpeedInNeededUnits = (
  speed: number,
  distanceUnits: "meters" | "yards"
): number => {
  if (distanceUnits === "meters") {
    //meters per second
    return +(speed / 3.6).toFixed(1);
  } else {
    //miles per hour
    return +(speed / 1.60934).toFixed(1);
  }
};
