import React, {useContext, useEffect, useState} from "react";
import {Form, ImageFromAssets, Modal} from "@/shared";
import {AuthContext, CustomerContext, ModalContext,} from "@/contexts";
import "./SubscriptionInfoModal.scss";
import {PlanEnum, PlanMappingEnum, SubscriptionReqStatusEnum} from "@/interfaces/subscriptions-interfaces";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {FormSelect} from "@/shared/Form/FormSelect/FormSelect";
import {getLeftDays} from "@/helpers/getLeftDays";
import {InfoListItem} from "@/shared/InfoListItem/InfoListItem";
import Api from "@/api/api";
import {toast} from "react-toastify";

dayjs.extend(relativeTime);

const selectOptions = Object.keys(PlanMappingEnum).map(key => ({
  text: PlanMappingEnum[key as keyof typeof PlanMappingEnum],
  value: PlanEnum[key as keyof typeof PlanEnum],
}));
interface Props {
}

const divider = <hr className='subscription-info-modal__divider'/>

export function SubscriptionInfoModal({}: Props) {
  const {customerData, getCustomerData} = useContext(CustomerContext);
  const {getUser} = useContext(AuthContext);

  const changeSubscriptionRequest = customerData?.changeSubscriptionRequest;
  const previousChangeSubscriptionRequest = customerData?.previousChangeSubscriptionRequest;
  const subscriptionInfo = customerData?.subscriptionInfo;

  const [loading, setLoading] = useState(false);
  const [newPlan, setNewPlan] = useState<PlanEnum | null>(null)
  const {closeModal, openModal} = useContext(ModalContext);

  const subscriptionLevel = subscriptionInfo?.subscriptionLevel
  const expirationDateTime = subscriptionInfo?.expirationDateTime
  const handlePlanChange = async () => {
    newPlan && newPlan !== subscriptionLevel && await handleSubscriptionChange(newPlan);
  };

  const handleSubscriptionChange = async (subscriptionLevel: string) => {
    setLoading(true)
    const updateUrl = `/v2/organization-admin/organizations/subscription/requests`
    try {
      await Api.post(updateUrl, {subscriptionLevel});
      getCustomerData();
      closeModal();
    } catch (error: any) {
      const {message} = error?.response?.data;
      if (message) {
        toast.error(message);
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setLoading(false)
    }
  }

  const listItems = [
    {
      title: 'Current plan:',
      primaryText: `${subscriptionLevel && PlanMappingEnum[subscriptionLevel]}`
    },
    {
      title: 'Expire date:',
      primaryText: dayjs(expirationDateTime).format("MM/DD/YYYY"),
      subtext: expirationDateTime ? getLeftDays(expirationDateTime) : ''
    }
  ]

  const handleOnChange = (option: string | number) => {
    setNewPlan(option as PlanEnum)
  }

  const selectNewPlan = (
    <Form.Row>
      <Form.Label>Select New Plan</Form.Label>
      <FormSelect
        loading={loading}
        options={selectOptions}
        onChange={handleOnChange}
        value={
          selectOptions.find(item => item.value === subscriptionLevel)?.text
        }
        selectIcon='arrow-down.svg'
      />
    </Form.Row>
  );

  const pendingNewPlan = (
    <div className='subscription-info-modal__list--pending'>
      {divider}
      <div className='subscription-info-modal__list--pending--text'>
        <p style={{flex: 1}}>Active request:</p>
        <p>Please wait for approval</p>
        <ImageFromAssets name='bars-rotate-fade.svg'/>
      </div>
      <InfoListItem
        title='Selected Plan:'
        primaryText={
          changeSubscriptionRequest ? PlanMappingEnum[changeSubscriptionRequest.subscriptionLevel] : ''}
      />
    </div>
  );

  const declinedNewPlan = (
    <div className='subscription-info-modal__list--declined'>
      {divider}
      <div className='subscription-info-modal__list--declined--text'>
        <span style={{flex: 1}}>Active request:</span>
        <span className='subscription-info-modal__list--declined--text--status'>Request Declined</span>
        <ImageFromAssets name='cross-red.svg'/>
      </div>
      <InfoListItem
        title='Selected Plan:'
        primaryText={
          previousChangeSubscriptionRequest?.subscriptionLevel ?
            PlanMappingEnum[previousChangeSubscriptionRequest.subscriptionLevel] : ''
        }
      />
      {divider}
      {selectNewPlan}
    </div>
  );

  const acceptedNewPlan = (
    <div className='subscription-info-modal__list--pending'>
      <div className='subscription-info-modal__list--pending--text'>
        <p style={{flex: 1}}></p>
        <p>Request Approved</p>
        <ImageFromAssets name='check-icon-green.svg'/>
      </div>
    </div>
  );

  let content;
  if ((!changeSubscriptionRequest && !previousChangeSubscriptionRequest)
    // || previousChangeSubscriptionRequest?.status === SubscriptionReqStatusEnum.ACCEPTED
  ) {
    content = selectNewPlan
  } else if (changeSubscriptionRequest?.status === SubscriptionReqStatusEnum.PENDING) {
    content = pendingNewPlan
  } else if (previousChangeSubscriptionRequest?.status === SubscriptionReqStatusEnum.REJECTED) {
    content = declinedNewPlan
  } else if (previousChangeSubscriptionRequest?.status === SubscriptionReqStatusEnum.ACCEPTED) {
    content = selectNewPlan
  }

  useEffect(() => {
    getCustomerData()
    getUser()
  }, []);

  return (
    <Modal className="subscription-info-modal">
      <Modal.Header
        className='subscription-info-modal__header'
        text='Subscription info'
      />
      {previousChangeSubscriptionRequest?.status === SubscriptionReqStatusEnum.ACCEPTED &&
        !changeSubscriptionRequest && acceptedNewPlan}
      <div className='subscription-info-modal__list'>
        {listItems.map(({title, primaryText, subtext}, index) => {
            if (subscriptionLevel === PlanEnum.FREE_VERSION && index === 1) {
              return null
            } else {
              return (
                <InfoListItem key={index} title={title} primaryText={primaryText} subtext={subtext}/>
              )
            }
          }
        )}
      </div>
      {content}
      <Modal.Footer>
        <Form.Button type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>
        <Form.Button loading={loading} onClick={handlePlanChange}>
          UPGRADE PLAN
        </Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
