import "./OverviewTableItem.scss";
import {OverviewTableItemAthletes} from "./OverviewTableItemAthletes/OverviewTableItemAthletes";
import {OverviewTableItemTeams} from "./OverviewTableItemTeams/OverviewTableItemTeams";

type Props = {
  leftSide: JSX.Element;
  rightSide: JSX.Element;
};

export function OverviewTableItem({leftSide, rightSide}: Props) {
  return (
    <div className="overview-table-item">
      {leftSide}
      {rightSide}
    </div>
  );
}

OverviewTableItem.Teams = OverviewTableItemTeams;
OverviewTableItem.Athletes = OverviewTableItemAthletes;
