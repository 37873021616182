import {AuthContext} from "@/contexts";
import {UserRoleEnum} from "@/interfaces/interface";
import {Tabs} from "./Tabs/Tabs";
import {useContext, useState} from "react";
import "./RolePicker.scss";
import Api from "@/api/api";

const roleTextByRoleEnum: { [key in UserRoleEnum]: string } = {
  [UserRoleEnum.ROLE_ADMIN]: "Super Admin",
  [UserRoleEnum.ROLE_ATHLETE]: "Athlete",
  [UserRoleEnum.ROLE_COACH]: "Coach",
  [UserRoleEnum.ROLE_ORGANIZATION_ADMIN]: "Admin",
};

export const RolePicker = () => {
  const {userRole, availableRoles, setUserRole} = useContext(AuthContext);

  const [loading, setLoading] = useState<boolean>(false);

  const isRolePickerHidden =
    !availableRoles || availableRoles?.length < 2 || !userRole;

  if (isRolePickerHidden) {
    return <></>;
  }

  const optionsFromRoles = availableRoles.map((role) => {
    return {
      text: roleTextByRoleEnum[role],
      value: role,
    };
  });

  const onTabClick = async (tabIndex: number) => {
    if (loading) return;

    try {
      setLoading(true);

      const role = optionsFromRoles[tabIndex].value;

      const res = await Api.put(
        `/client/users/roles/dashboard/select?role=${role}`
      );

      if (res.status === 200) {
        window.location.href = "";

        setUserRole(role);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tabs
        loading={loading}
        activeTab={userRole}
        tabs={optionsFromRoles.reverse()}
        onTabClick={onTabClick}
        className="role-picker"
      />
  );
};
