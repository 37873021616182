import {CoachAthletesListModal} from "@/components/Modals/CoachAthletesListModal/CoachAthletesListModal";
import {ModalContext} from "@/contexts";
import {ICoachInTeam, ITeam} from "@/interfaces/interface";
import {useContext} from "react";
import "./CoachTableItem.scss";

type Props = {
  coach: ICoachInTeam;
  handleDeleteCoachFromOrganization: () => Promise<void>;
  selectedOrganization: ITeam;
};

export function CoachTableItem({
                                 coach,
                                 handleDeleteCoachFromOrganization,
                                 selectedOrganization,
                               }: Props) {

  const {email, username, name} = coach || {};
  const {pin} = selectedOrganization;

  const {openModal} = useContext(ModalContext);

  const handleOpenAthletesModal = () => {
    openModal(
      <CoachAthletesListModal
        coach={coach}
        selectedOrganization={selectedOrganization}
      />
    );
  };

  return (
    <div className="coach-table-item">
      <div className="name">{name}</div>
      <div className="pin">{email}</div>
      {/*<div onClick={handleOpenAthletesModal} className="athletes">*/}
      {/*  Athletes: {athletes?.length}*/}
      {/*</div>*/}

      {/*<div className="actions">*/}
      {/*<ImageFromAssets onClick={handleExportExcel} name="actions-excel.svg" />*/}
      {/*</div>*/}
    </div>
  );
}
