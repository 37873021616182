import {ImageFromAssets} from "@/shared";
import "./Layout.scss";
import {Sidebar} from "./Sidebar/Sidebar";
import {Header} from "./Header/Header";
import {IRoute} from "@/interfaces/interface";

interface Props {
  children: React.ReactNode;
  routes: IRoute[];
}

export const Layout = ({children, routes}: Props): JSX.Element => {
  return (
    <div className="page">
      <header className="page__header">
        <Header routes={routes}/>
      </header>
      <aside className="page__sidebar">
        <ImageFromAssets
          className="page__sidebar__logo"
          name="shred-logo.png"
        />
        <Sidebar routes={routes}/>
      </aside>
      <main className="page__content">{children}</main>
      <footer className="page__footer"></footer>
    </div>
  );
};
