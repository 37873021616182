import "./FormRow.scss";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

export function FormRow({className, children}: Props): JSX.Element {
  return <div className={`form-row ${className}`}>{children}</div>;
}
