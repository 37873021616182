import {CoachAthleteTableItem} from "@/components/TableItems/CoachAthleteTableItem/CoachAthleteTableItem";
import {ICoachOrganizationData} from "@/interfaces/interface";
import {Form, ImageFromAssets, ItemContainer} from "@shared";
import {useContext} from "react";
import "./CoachTeamAthletes.scss";
import {ModalContext} from "@/contexts";
import {CoachAddAthleteModal} from "@/components/Modals/CoachAddAthleteModal/CoachAddAthleteModal";
import {CoachUploadAthletesModal} from "@/components/Modals/CoachUploadAthletesModal/CoachUploadAthletesModal";
import {EmptyItemContainer} from "@/shared/EmptyItemContainer/EmptyItemContainer";
import {SelectAddAthleteModal} from "@/components/Modals/SelectAddAthleteModal/SelectAddAthleteModal";
import {SelectExistingAthleteModal} from "@/components/Modals/SelectExistingAthleteModal/SelectExistingAthleteModal";

type Props = {
  selectedTeam: ICoachOrganizationData;
};

export function CoachTeamAthletes({selectedTeam}: Props) {
  const {approvedAthletes} = selectedTeam || {};
  const {id: teamId, pin} = selectedTeam || {};

  const athletesExist = !approvedAthletes?.length;

  const getEmptyText = () => {
    if (!selectedTeam?.id) {
      return "Select some team";
    }
    if (athletesExist) {
      return "No Athletes";
    }
  };

  const {openModal} = useContext(ModalContext);

  const areSomeAthleteExist = approvedAthletes?.length > 0;

  const handleAddAthlete = () => {
    openModal(<SelectAddAthleteModal openAthleteCreateModal={handleAddNewAthlete}
                                     openAthleteSelectModal={handleSelectAthlete}/>)
  }

  const handleAddNewAthlete = () => {
    openModal(<CoachAddAthleteModal coachGroupId={selectedTeam.id}/>);
  };

  const handleSelectAthlete = () => {
    openModal(<SelectExistingAthleteModal selectedTeam={selectedTeam}/>);
  };

  const handleAthletesUpload = () => {
    openModal(
      <CoachUploadAthletesModal coachGroupId={selectedTeam.pin} teamId={selectedTeam.id}/>
    );
  };

  return (
    <ItemContainer className={`organization-coaches`}>
      <ItemContainer.Header
        className="organization-coaches__header"
        leftSide={<div>Athletes</div>}
        rightSide={
          <>
            <Form.Button
              onClick={handleAthletesUpload}
              hidden={!selectedTeam?.id}
            >
              <ImageFromAssets name="cloud-computing.svg"/>
              UPLOAD ATHLETE
            </Form.Button>
            <Form.Button
              onClick={handleAddAthlete}
              hidden={!selectedTeam?.id}
            >
              <ImageFromAssets name="user-approve.svg"/>
              ADD ATHLETE
            </Form.Button>
          </>
        }
      />

      <div className="organization-coaches__body">
        {areSomeAthleteExist && (
          <div>
            {approvedAthletes.map((athlete, index) => (
              <CoachAthleteTableItem
                athlete={athlete}
                teamId={teamId}
                teamPin={pin}
                key={athlete.username}
              />
            ))}
          </div>
        )}
      </div>
      {!areSomeAthleteExist && (
        <div style={{height: 'calc(100% - 110px)'}}>
          <EmptyItemContainer text={getEmptyText()}/>
        </div>
      )}
    </ItemContainer>
  );
}
