import Api from "@/api/api";
import {AuthContext, CoachContext, CustomerContext, ModalContext} from "@/contexts";
import {IAthlete, ICoachOrganizationData, ITeam} from "@/interfaces/interface";
import {Form, Modal} from "@/shared";
import {useContext, useEffect, useState} from "react";
import "./SelectExistingAthleteModal.scss";
import {toast} from "react-toastify";

type Props = {
  selectedTeam: ITeam | ICoachOrganizationData;
};

export function SelectExistingAthleteModal({selectedTeam}: Props) {
  const {getTeams: getCustomerTeams} = useContext(CustomerContext);
  const {getTeams: getCoachTeams} = useContext(CoachContext);
  const {closeModal} = useContext(ModalContext);
  const {userRole} = useContext(AuthContext);

  const [athletes, setAthletes] = useState<IAthlete[]>([])
  const [selectedAthleteUsername, setSelectedAthleteUsername] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState("");

  const {id: teamId} = selectedTeam;

  const isCoach = userRole === "ROLE_COACH";

  const itemsForDropdown = athletes.map((athlete) => {
    return {
      text: athlete.userData?.name || athlete.userData?.email || "",
      value: athlete.username,
    };
  });

  const addAthlete = async () => {
    try {
      setLoading(true);

      if (!selectedAthleteUsername) {
        setFormError("Required field");
      }

      const res = await Api.patch(
        isCoach ?
          `/v2/coach/organizations/teams/${teamId}/athletes/add` :
          `/v2/organization-admin/organizations/teams/${teamId}/athletes/add`,
        {
          athleteUsername: selectedAthleteUsername,
        }
      );
      if (res.status === 200) {
        if (isCoach) {
          await getCoachTeams().then(() => closeModal());
        } else {
          await getCustomerTeams().then(() => closeModal());
        }
        closeModal();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Some error");
    } finally {
      setLoading(false);
    }
  };

  const getAvailableAthletes = async () => {
    try {
      setLoading(true);

      const res = await Api.get(
        isCoach ?
          `/v2/coach/organizations/teams/${teamId}/available-athletes` :
          `/v2/organization-admin/organizations/teams/${teamId}/available-athletes`,
      );
      if (res.status === 200) {
        setAthletes(res.data)
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Some error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAvailableAthletes()
  }, []);

  return (
    <Modal>
      <Modal.Header text="Add Athlete"/>
      <Form.Row>
        <Form.Label>Select Athlete</Form.Label>
        <Form.Select
          value={selectedAthleteUsername}
          errorMessage={formError}
          options={itemsForDropdown}
          placeholder="Select Athlete"
          onChange={(val) => setSelectedAthleteUsername(val.toString())}
        />
      </Form.Row>
      <Modal.Footer>
        <Form.Button type="outline" loading={loading} onClick={closeModal}>
          CANCEL
        </Form.Button>

        <Form.Button loading={loading} onClick={addAthlete}>
          ADD
        </Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
