import Api from "@/api/api";
import {AddAutoapprovedCoachModal} from "@/components";
import {CustomerContext, ModalContext} from "@/contexts";
import {ICoach} from "@/interfaces/interface";
import {Form, ImageFromAssets, ItemContainer} from "@/shared/";
import {useContext, useState} from "react";
import "./AutoApproveRequestHeader.scss";

type Props = {
  coachesByStatus: ICoach[];
};

export function AutoApproveRequestHeader({coachesByStatus}: Props) {
  const [loading, setLoading] = useState(false);
  const {openModal} = useContext(ModalContext);
  const {getCustomerData} = useContext(CustomerContext);

  const handleAddAutoApprovedCoach = () => {
    openModal(<AddAutoapprovedCoachModal/>);
  };

  const handleRemoveAllClick = async () => {
    try {
      setLoading(true);

      const res = await Api.delete(
        `v2/organization-admin/organizations/teams/auto-approve-emails`
      );
      if (res.status === 200) {
        await getCustomerData();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <ItemContainer.Header
      className="new-request-header"
      leftSide={<div>Auto Approved Requests</div>}
      rightSide={
        <div className="actions">
          <Form.Button onClick={handleAddAutoApprovedCoach}>
            <ImageFromAssets name="user-approve.svg"/>
            ADD COACH
          </Form.Button>
        </div>
      }
    />
  );
}
