import {NavLink} from "react-router-dom";
import "./Sidebar.scss";
import {ImageFromAssets} from "@shared";
import {IRoute} from "@/interfaces/interface";
import {useContext} from "react";
import {AuthContext, ModalContext} from "@/contexts";
import {UpdateYourPlanModal} from "@/components/Modals/UpdateYourPlanModal/UpdateYourPlanModal";

interface Props {
  routes: IRoute[];
}

export function Sidebar({routes}: Props): JSX.Element {
  const {openModal} = useContext(ModalContext);

  const {subscriptionInfo} = useContext(AuthContext);

  const openUpdateModal = (title: string) => {
    openModal(<UpdateYourPlanModal page={title}/>)
  }

  return (
    <ul className="sidebar">
      {routes.map(({title, iconName, to, deniedPlans}) => {
        if (deniedPlans && subscriptionInfo && deniedPlans.includes(subscriptionInfo.subscriptionLevel)) {
          return (
            <div key={title} className={"sidebar__link sidebar__link--denied"} onClick={() => openUpdateModal(title)}>
              <ImageFromAssets name={iconName}/>
              <div className={"sidebar__link--denied__title"}>{title}</div>
            </div>
          )
        } else {
          return (
            <NavLink key={title} to={to} className={"sidebar__link"}>
              <ImageFromAssets name={iconName}/>
              <div className={"sidebar__link__title"}>{title}</div>
            </NavLink>
          )
        }
      })}
    </ul>
  );
}
