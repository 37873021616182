import {ReactEventHandler, ReactNode} from "react";
import {FormButton} from "./FormButton/FormButton";
import {FormCheckbox} from "./FormCheckbox/FormCheckbox";
import {FormInput} from "./FormInput/FormInput";
import {FormLabel} from "./FormLabel/FormLabel";
import {FormRow} from "./FormRow/FormRow";
import {FormSelect} from "./FormSelect/FormSelect";
import {FormToggle} from "./FormToggle/FormToggle";

export interface Props {
  className?: string;
  children?: ReactNode;
  onSubmit?: ReactEventHandler;
  autoComplete?: string;
}

export const Form = ({
                       className,
                       onSubmit,
                       children,
                       autoComplete,
                     }: Props): JSX.Element => {
  const submitHandler = (event: React.SyntheticEvent) => {
    event.preventDefault();

    onSubmit && onSubmit(event);
  };

  return (
    <form
      className={`form ${className}`}
      onSubmit={submitHandler}
      autoComplete={autoComplete}
    >
      {children}
    </form>
  );
};

Form.Toggle = FormToggle;
Form.Checkbox = FormCheckbox;
Form.Input = FormInput;
Form.Label = FormLabel;
Form.Select = FormSelect;
Form.Row = FormRow;
Form.Button = FormButton;
