import {Coach, UserRoleEnum} from "@/interfaces/interface";
import {ItemContainer} from "@shared";
import "./AdminCoachesList.scss";
import {EmptyItemContainer} from "@/shared/EmptyItemContainer/EmptyItemContainer";
import {useContext} from "react";
import {ModalContext} from "@/contexts";
import {EditCoachModal} from "@/components/Modals/EditCoachModal/EditCoachModal";
import Api from "@/api/api";

type Props = {
    setSelectedCoach: (coach: Coach) => void;
    coaches?: Coach[];
    selectedOrganizationID: string;
    updateClients: () => Promise<void>;
};

export function AdminCoachesList({
                                     setSelectedCoach,
                                     coaches,
                                     selectedOrganizationID,
                                     updateClients
                                 }: Props) {
    const {openModal} = useContext(ModalContext);

    const onTableItemClick = async (username: string) => {
        const neededCoach = coaches?.find((coach) => coach.username === username);



        if (neededCoach) {
            const response = await Api.get<any>(
                `/admin/users/${neededCoach.username}`
            );
            if (response.data) {
                const isOrgAdminRoleExist = response.data.roles.find((el: string) => el === UserRoleEnum.ROLE_ORGANIZATION_ADMIN);
                if (isOrgAdminRoleExist) {
                    neededCoach.role = 'Organization Admin';
                } else {
                    neededCoach.role = 'Coach';
                }
            }
            setSelectedCoach(neededCoach);
            openModal(<EditCoachModal updateClients={updateClients} selectedOrganizationID={selectedOrganizationID} coach={neededCoach}/>);
        }
    };

    const areSomeCoachExist = coaches && coaches.length > 0;


    return (
    <ItemContainer className={`admin-coaches-list`}>
      <ItemContainer.Header
        className="admin-coaches-list__header"
        leftSide={<div>{`Coaches`}</div>}
      />

      <div
        className="admin-coaches-list__body"
        style={{height: "calc(100% - 99px)"}}
      >
        {areSomeCoachExist && (
          <div className="admin-coaches-list__body__list">
            {coaches.map((coach, index) => {
              const {name = "", email = "", username = ''} = coach || {};
              return (
                <div
                  key={email}
                  className={`admin-coaches-list__body__list__item`}
                  onClick={() => onTableItemClick(username)}
                >
                  <div className="name">{name}</div>
                  <div className="email">{email}</div>
                  {/*<div className="pin">{pin}</div>*/}

                  {/*<div className="actions">*/}
                  {/*  <ImageFromAssets*/}
                  {/*    onClick={handleExportExcel}*/}
                  {/*    name="actions-excel.svg"*/}
                  {/*  />*/}
                  {/*</div>*/}
                </div>
              );
            })}
          </div>
        )}

        {!areSomeCoachExist && (
          <EmptyItemContainer text="There are no coaches"/>
        )}
      </div>
    </ItemContainer>
  );
}
