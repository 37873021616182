import "./FormToggle.scss";
import {ChangeEventHandler} from "react";

export interface Props {
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
}

export function FormToggle({className, onChange, checked}: Props) {
  return (
    <label className={`toggle-switch ${className}`}>
      <input type="checkbox" checked={checked} onChange={onChange}/>
      <span className="switch"/>
    </label>
  );
}
