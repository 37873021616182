import {ICoachAthletesRequests, UserStatusEnum,} from "@/interfaces/interface";
import {ItemContainer} from "@/shared";
import {RequestsBodyHeader} from "./RequestsBodyHeader/RequestsBodyHeader";
import {RequestBody} from "./RequestsBody/RequestBody";
import "./CoachUserAthleteRequests.scss";

type Props = {
  activeStatus: UserStatusEnum;
  athletesByStatus: ICoachAthletesRequests[];
};

export function CoachUserAthleteRequests({
                                           activeStatus,
                                           athletesByStatus,
                                         }: Props) {
  return (
    <ItemContainer className="coach-requests">
      <RequestsBodyHeader
        activeStatus={activeStatus}
        athletesByStatus={athletesByStatus}
      />

      <RequestBody
        activeStatus={activeStatus}
        athletesByStatus={athletesByStatus}
      />
    </ItemContainer>
  );
}
