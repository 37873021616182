import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {ReactNode, useContext} from "react";
import {Layout} from "@components";
import {IF} from "@shared";
import {ToastContainer} from "react-toastify";
import {
  AuthContext,
  CoachContextProvider,
  CustomerContextProvider,
  ModalContextProvider,
  SuperadminContextProvider
} from "./contexts";
import {UserRoleEnum} from "./interfaces/interface";
import {signedInRoutes, signOutRoutes} from "./routes";
import ProtectedRoute from "@/components/ProtectedRoute/ProtectedRoute";

type Props = { children: ReactNode };

type ContextByRole = {
  [key in UserRoleEnum]: ({children}: Props) => JSX.Element;
};

const contextProviderByRole: ContextByRole = {
  [UserRoleEnum.ROLE_ORGANIZATION_ADMIN]: CustomerContextProvider,
  [UserRoleEnum.ROLE_COACH]: CoachContextProvider,
  [UserRoleEnum.ROLE_ATHLETE]: CustomerContextProvider,
  [UserRoleEnum.ROLE_ADMIN]: SuperadminContextProvider,
};

function App() {
  const {isSignedIn, userRole} = useContext(AuthContext);

  const ContextProviderByRole =
    contextProviderByRole[userRole ? userRole : UserRoleEnum.ROLE_ORGANIZATION_ADMIN];

  const routes =
    signedInRoutes[userRole ? userRole : UserRoleEnum.ROLE_ATHLETE];

  return (
    <ContextProviderByRole>
      <ModalContextProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme="light"
        />
        <BrowserRouter>
          <IF condition={!isSignedIn}>
            <Routes>
              {signOutRoutes.map(({to, component}) => {
                return <Route key={to} path={to} element={component}/>;
              })}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </IF>

          <IF condition={Boolean(isSignedIn && userRole)}>
            <Layout routes={routes}>
              <Routes>
                {routes.map(({to, component, deniedPlans}) =>
                  <Route
                    key={to}
                    path={to}
                    element={
                      <ProtectedRoute redirectPath='/info' deniedPlans={deniedPlans}>
                        {component}
                      </ProtectedRoute>
                    }
                  />
                )}
                <Route path="*" element={<Navigate to="/" replace/>}/>
              </Routes>
            </Layout>
          </IF>
        </BrowserRouter>
      </ModalContextProvider>
    </ContextProviderByRole>
  );
}

export default App;
