import React, {useState} from "react";
import Api from "../api/api";
import {ICoachAthletesRequests, ICustomerMyResponse, IGetOrganizationResponse, ITeam,} from "@/interfaces/interface";

export interface ICustomerContext {
  getAthletesRequests: () => Promise<void>;
  athletesRequests: ICoachAthletesRequests[];
  customerData: ICustomerMyResponse | null;
  getCustomerData: () => Promise<void>;
  teams: ITeam[];
  getTeams: () => Promise<void>;
  getOrganizationsSuperAdmin: () => Promise<void>;
}

type Props = {
  children?: React.ReactNode;
};

const CustomerContext = React.createContext({} as ICustomerContext);

const CustomerContextProvider = ({children}: Props) => {
  const [customerData, setCustomerData] = useState<ICustomerMyResponse | null>(
    null
  );
  const [athletesRequests, setAthletesRequests] = useState<
    ICoachAthletesRequests[]
  >([]);

  const [teams, setTeams] = useState<ITeam[]>([]);

  const getCustomerData = async () => {
    const response = await Api.get<ICustomerMyResponse>("v2/organization-admin/organizations/my");
    if (response.data) {
      setCustomerData(response.data);
    }
  };

  const getAthletesRequests = async () => {
    const res = await Api.get<ICoachAthletesRequests[]>(
      "/v2/organization-admin/organizations/teams/athletes/requests"
    );

    if (res.status === 200) {
      setAthletesRequests(res.data);
    }
  };

  const getTeams = async () => {
    const res = await Api.get<IGetOrganizationResponse>(
      "v2/organization-admin/organizations/teams"
    );

    if (res.status === 200) {
      setTeams(res.data.data);
    }
  };

  const getOrganizationsSuperAdmin = async () => {
    const res = await Api.get<IGetOrganizationResponse>(
      "/v2/admin/organizations/teams/${teamId}"
    );

    if (res.status === 200) {
      setTeams(res.data.data);
    }
  };

  const state = {
    getAthletesRequests,
    athletesRequests,
    customerData,
    getCustomerData,
    getTeams,
    teams,
    getOrganizationsSuperAdmin
  };

  return (
    <CustomerContext.Provider value={state}>
      {children}
    </CustomerContext.Provider>
  );
};

export {CustomerContextProvider, CustomerContext};
