import Api from "@/api/api";
import {AuthContext, CoachContext, ModalContext} from "@/contexts";
import {downloadFile} from "@/helpers/download-file";
import {getFileFormData} from "@/helpers/getFileFormData";
import {Athlete3} from "@/interfaces/interface";
import {Form, Modal} from "@/shared";
import {ChangeEvent, useContext, useRef, useState} from "react";
import {toast} from "react-toastify";
import "./CoachUploadAthletesModal.scss";

type Props = {
  coachGroupId: string;
  teamId?:string;
};

export function CoachUploadAthletesModal({coachGroupId, teamId}: Props) {
  const {closeModal} = useContext(ModalContext);
  const {getTeams} = useContext(CoachContext);
  const {userRole} = useContext(AuthContext);

  const isCoach = userRole === "ROLE_COACH";

  const [loading, setLoading] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const downloadAthletesTemplate = async (type: "csv" | "xlsx") => {
    const response = await Api.post<Blob>(
      `/v2/organization-admin/organizations/teams/athletes/upload/template/${type}`,
      {},
      {
        responseType: "blob",
      }
    );
    downloadFile(response.data, `athletes-template.${type}`);
  };

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const uploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const {files} = event.target;

    if (files) {
      const type = files[0].name.split(".").pop();
      const formData = getFileFormData(files);

      try {
        setLoading(true);

        const response = await Api.post<Athlete3>(
          isCoach ?
            `/v2/coach/organizations/teams/${teamId}/athletes/upload/${type}` :
            `/v2/organization-admin/organizations/teams/${coachGroupId}/athletes/upload/${type}`,
          formData
        );

        if (response.status === 200) {
          await getTeams().then(() => closeModal());
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message || "Some error");
        closeModal();
      } finally {
        // closeModal()
        setLoading(false);
      }
    }
  };

  return (
    <Modal>
      <Modal.Header text="Upload Athlete"/>
      <Form.Label className="upload-modal__text">
        Select a CSV or XLSX file to import athletes with the following headers:
      </Form.Label>
      <Form.Label className="upload-modal__text">
        <span>name, email, weight (lbs), height (inches), gender</span>
      </Form.Label>
      <Form.Row className="upload-modal__body">
        <Form.Button
          className="upload-modal__body__button"
          type="link"
          onClick={() => downloadAthletesTemplate("csv")}
        >
          Download CSV Template
        </Form.Button>
        <Form.Button
          className="upload-modal__body__button"
          type="link"
          onClick={() => downloadAthletesTemplate("xlsx")}
        >
          Download XLSX Template
        </Form.Button>
      </Form.Row>

      <Modal.Footer>
        <Form.Button type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>
        <Form.Button disabled={loading} onClick={handleUploadClick}>
          UPLOAD FILE
          <input
            ref={inputRef}
            type="file"
            accept=".csv, .xlsx"
            onChange={uploadFile}
            hidden
          />
        </Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
