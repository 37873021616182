import "./FormButton.scss";
import {clsx} from "clsx";

interface Props {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
  loading?: boolean;
  type?: "primary" | "outline" | "decline" | "cancel" | "text" | "link";
  hidden?: boolean;
}

export function FormButton({
                             className,
                             children,
                             disabled,
                             loading,
                             onClick,
                             type = "primary",
                             hidden = false,
                           }: Props): JSX.Element {
  const isDisabled = loading || disabled;

  const rootClassNames = clsx("form-button", className, {
    "form-button--hidden": hidden,
    "form-button--outline": type === "outline",
    "form-button--decline": type === "decline",
    "form-button--text": type === "text",
    "form-button--link": type === "link",
    "form-button--cancel": type === "cancel",
    "form-button--disabled": isDisabled,
  });

  return (
    <button onClick={onClick} disabled={isDisabled} className={rootClassNames}>
      {children}
    </button>
  );
}
