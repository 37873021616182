import React, {ReactNode, useEffect, useRef, useState} from "react";
import {useOnClickOutside} from "@/hooks";
import "./Select.scss";
import "./FormSelect.scss"
import {clsx} from "clsx";
import {IFormSelectOption} from "@/interfaces/interface";
import {ImageFromAssets} from "@/shared";

export const FormSelect: React.FC<{
  placeholder?: string;
  options: IFormSelectOption[];
  onChange: (val: string | number) => void;
  disabled?: boolean;
  errorMessage?: string;
  value?: undefined | string | ReactNode;
  className?: string;
  loading?: boolean;
  isForm?: boolean; // to change styling variant: false - with blue border&bgcolor, true (default) - regular select
  selectIcon?: string;
}> = ({
        placeholder,
        options,
        onChange,
        disabled,
        errorMessage,
        value,
        className,
        loading,
        isForm,
        selectIcon
      }) => {
  const [selectedOption, setSelectedOption] = useState(value || "");
  const [showDropdown, setShowDropdown] = useState(false);
  const showDropdownHandler = () => !disabled && setShowDropdown(!showDropdown);
  const selectPlaceholder = placeholder || "Choose an option";
  const selectContainerRef = useRef(null);

  let optionsWithEmpty: IFormSelectOption[] =
    options.length > 0 ? options : [{text: "List is empty...", value: "#"}];

  useEffect(() => {
    if (value === null) {
      setSelectedOption("");
    }

    return () => {
    };
  }, [value]);

  const clickOutsideHandler = () => setShowDropdown(false);

  useOnClickOutside(selectContainerRef, clickOutsideHandler);

  const updateSelectedOption = (option: IFormSelectOption) => {
    onChange(option.value);
    setSelectedOption(option.text);
    setShowDropdown(false);
  };

  const rootClassNames = clsx(
    {
      "form-select-container": isForm,
      "select-container": !isForm,
    },
    className,
    {
      "select-container--active": showDropdown && !isForm,
      "select-container--error": errorMessage && !isForm,
      "form-select-container--active": showDropdown && isForm,
      "form-select-container--error": errorMessage && isForm,
    }
  );

  return (
    <div
      className={rootClassNames}
      onClick={showDropdownHandler}
      ref={selectContainerRef}
    >
      <div
        className={`selected-text ${showDropdown ? "selected-text--active" : ''} ${
          !selectedOption ? "selected-text--placeholder" : ''
        }`}
      >
        <>{selectedOption ? selectedOption : selectPlaceholder}</>
        <ImageFromAssets
          name={loading ? 'spinner.svg' : selectIcon ? selectIcon : 'arrow-down-white.svg'}
          className={`selected-text__icon ${showDropdown ? "selected-text__icon--active" : ''}`}
        />
      </div>

      <div
        className={`select-options ${
          showDropdown ? "select-options--show" : "select-options--hide"
        }`}
      >
        {optionsWithEmpty.map((option, index) => {
          const {text, value} = option;
          return (
            <div
              key={value}
              className="select-options__option"
              onClick={() => {
                if (value !== "#") {
                  updateSelectedOption(option);
                }
              }}
            >
              {text}
              {option.text === selectedOption &&
                  <ImageFromAssets name='check-icon.svg' className={`select-options__option__icon`}/>
              }
            </div>
          );
        })}
      </div>

      <div className="select-container__error-message">{errorMessage}</div>
    </div>
  );
};

FormSelect.defaultProps = {
  isForm: true
}