import React from "react";
import "./ItemContainerHeader.scss";

type Props = {
  leftSide: React.ReactNode;
  rightSide?: React.ReactNode;
  className?: string;
};

export function ItemContainerHeader({leftSide, rightSide, className}: Props) {
  return (
    <div className={`item-container-header ${className}`}>
      {leftSide}
      {rightSide}
    </div>
  );
}
