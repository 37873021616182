import {CreateTeamModal, TeamTableItem,} from "@components";
import {CustomerContext, ModalContext} from "@/contexts";
import {Form, ImageFromAssets, ItemContainer} from "@shared";
import {useContext} from "react";
import "./AllTeams.scss";
import {EmptyItemContainer} from "@/shared/EmptyItemContainer/EmptyItemContainer";

type Props = {
  setSelectedOTeamID: (id: string) => void;
  selectedTeamID: string;
};

export function AllTeams({
                           setSelectedOTeamID,
                           selectedTeamID,
                         }: Props) {
  const {openModal} = useContext(ModalContext);

  const {teams} = useContext(CustomerContext);

  const handleAddTeam = () => {
    openModal(<CreateTeamModal/>);
  };

  const organizationsExist = Boolean(teams.length);

  return (
    <ItemContainer className={`admin-all-organizations`}>
      <ItemContainer.Header
        className="admin-all-organizations__header"
        leftSide={<div>All Teams</div>}
        rightSide={
          <Form.Button
            onClick={handleAddTeam}
            className="admin-all-organizations__header__add-button"
          >
            <ImageFromAssets name="user-approve.svg"/>
            ADD TEAM
          </Form.Button>
        }
      />

      <div className="admin-all-organizations__body">
        {organizationsExist && (
          <div>
            {teams.map((organization, index) => (
              <TeamTableItem
                onClick={() => setSelectedOTeamID(organization.id)}
                isSelected={organization.id === selectedTeamID}
                key={index}
                team={organization}
              />
            ))}
          </div>
        )}
      </div>
      {!organizationsExist && (
        <div style={{height: 'calc(100% - 110px)'}}>
          <EmptyItemContainer text="You do not have added teams yet"/>
        </div>
      )}
    </ItemContainer>
  );
}
