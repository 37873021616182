import {ICoach, UserStatusEnum} from "@/interfaces/interface";
import {ItemContainer} from "@/shared";
import {RequestsBodyHeader} from "./RequestsBodyHeader/RequestsBodyHeader";
import {RequestBody} from "@/components";
import "./CoachRequests.scss";

type Props = {
  activeStatus: UserStatusEnum;
  coachesByStatus: ICoach[];
};

export function CoachRequests({activeStatus, coachesByStatus}: Props) {
  return (
    <ItemContainer className="coach-requests">
      <RequestsBodyHeader
        activeStatus={activeStatus}
        coachesByStatus={coachesByStatus}
      />

      <RequestBody
        activeStatus={activeStatus}
        coachesByStatus={coachesByStatus}
      />
    </ItemContainer>
  );
}
