import {ICoachAthletesRequests,} from "@/interfaces/interface";
import dayjs from "dayjs";
import {Form, ImageFromAssets} from "@/shared";
import {useContext} from "react";
import {CustomerContext} from "@/contexts";
import Api from "@/api/api";

var localizedFormat = require("dayjs/plugin/localizedFormat");

dayjs.extend(localizedFormat);

type Props = {
  athletesByStatus: ICoachAthletesRequests[];
};

export function RequestBodyAutoApproved({athletesByStatus}: Props) {
  const {getCustomerData} = useContext(CustomerContext);

  const handleRemoveClick = async (organizationId: string, email: string) => {
    const res = await Api.patch(
      `admin/customers/organizations/${organizationId}/auto-approve-emails/remove?email=${email}`
    );
    if (res.status === 200) {
      await getCustomerData();
    }
  };

  return (
    <>
      {athletesByStatus.map((athlete, index) => {
        const {timestamp, userData} = athlete;
        const {email = "", name = "", username} = userData || {};

        return (
          <div key={index} className="request-body-new">
            <div className="name-column">
              <div>{name}</div>
              <div>{email}</div>
            </div>

            <div className="date-column">
              {dayjs(new Date(timestamp)).format("lll")}
            </div>

            <div className="actions-column">
              <Form.Button
                // onClick={() => handleRemoveClick(organizationId, email)}
                className="decline-button"
              >
                <ImageFromAssets name="user-decline.svg"/>
              </Form.Button>
            </div>
          </div>
        );
      })}
    </>
  );
}
