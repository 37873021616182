interface Props {
  condition: boolean;
  children: JSX.Element;
}

export function IF({condition, children}: Props): JSX.Element | null {
  if (condition) {
    return children;
  }

  return null;
}
