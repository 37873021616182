import {Form, Modal} from "@/shared";
import "./SelectAddCoachModal.scss";
import {useContext, useRef} from "react";
import {ModalContext} from "@/contexts";

type Props = {
  openCoachCreateModal: () => void;
  openCoachSelectModal: () => void;
};

export function SelectAddCoachModal({openCoachSelectModal, openCoachCreateModal}: Props) {
  const ref = useRef(null);
  const {closeModal} = useContext(ModalContext);

  return (
    <Modal innerRef={ref} className='select-add-athlete'>
      <Modal.Header text="Add Coach"/>
      <Form.Button onClick={openCoachCreateModal}>
        CREATE NEW COACH
      </Form.Button>
      <Form.Button type="outline" onClick={openCoachSelectModal}>
        ADD COACH
      </Form.Button>
      <Form.Button type="cancel" className='select-add-athlete__close-btn' onClick={closeModal}>
        CLOSE
      </Form.Button>
    </Modal>
  );
}
