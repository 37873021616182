import Api from "@/api/api";
import {CustomerContext, ModalContext} from "@/contexts";
import {Form} from "@/shared";
import {Modal} from "@/shared/Modal/Modal";
import {useContext, useState} from "react";
import "./CreateTeamModal.scss";
import {toast} from "react-toastify";

type Props = {};

type FormValue = {
  name: string;
};

const initialState = {
  name: "",
};

export function CreateTeamModal({}: Props) {
  const {closeModal} = useContext(ModalContext);

  const {getTeams} = useContext(CustomerContext);

  const [formValue, setFormValue] = useState<FormValue>(initialState);
  const [formError, setFormError] = useState<FormValue>(initialState);
  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const {name} = formValue;

  const validate = () => {
    let error = "";

    if (name?.length < 5) {
      error = "Team Name should be longer than 4 symbols";

      setFormError({
        ...initialState,
        name: error,
      });

      return error;
    }

  };

  const handleConfirm = async () => {
    const error = validate();
    if (error) {
      toast.error(error);
      return;
    }

    try {
      setLoading(true);

      const res = await Api.post("admin/customers/organizations", formValue);

      if (res.status === 200) {
        await getTeams();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);

      closeModal();
    }
  };

  return (
    <Modal>
      <Modal.Header text="Add Team"/>

      <Form.Row>
        <Form.Label>Team Name</Form.Label>
        <Form.Input
          maxLength={40}
          placeholder={"Name"}
          errorMessage={formError.name}
          onChange={handleChange}
          value={name}
          name={"name"}
        />
      </Form.Row>
      <Modal.Footer>
        <Form.Button type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>

        <Form.Button loading={loading} onClick={handleConfirm}>
          SAVE
        </Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
