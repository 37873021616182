import {ModalContext} from "@/contexts";
import {Form, Modal} from "@/shared";
import {useContext, useState} from "react";
import "./ConfirmationModal.scss";

/* eslint-disable-next-line */
type Props = {
  callback: () => Promise<void>;
  headerText: string;
  message?: string;
  secondaryMessage?: string | JSX.Element;
  confirmButtonText?: string;
  cancelButtonText?: string;
};

export const ConfirmationModal = ({
                                    callback,
                                    message,
                                    secondaryMessage,
                                    headerText = "Are you sure?",
                                    confirmButtonText = "Remove",
                                    cancelButtonText = "Cancel",
                                  }: Props) => {
  const {closeModal} = useContext(ModalContext);

  const [loading, setLoading] = useState(false);

  return (
    <Modal className="confirmation-modal">
      <Form.Row className="confirmation-modal__header">
        <div className="confirmation-modal__header__title">{headerText}</div>
      </Form.Row>

      {message && (
        <Form.Row className="confirmation-modal__body">
          <div className="confirmation-modal__body__text">{message}</div>
        </Form.Row>
      )}

      {secondaryMessage && (
        <Form.Row className="confirmation-modal__body">
          <div className="confirmation-modal__body__text">
            {secondaryMessage}
          </div>
        </Form.Row>
      )}

      <div className="confirmation-modal__footer">
        <Form.Button type="outline" onClick={closeModal}>
          {cancelButtonText}
        </Form.Button>
        <Form.Button
          loading={loading}
          onClick={async () => {
            try {
              setLoading(true);

              await callback();
            } catch (error) {
            } finally {
              setLoading(false);
              closeModal();
            }
          }}
        >
          {confirmButtonText}
        </Form.Button>
      </div>
    </Modal>
  );
};
