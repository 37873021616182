import "./OverviewEditItems.scss";
import {ImageFromAssets} from "@shared";

export function OverviewEditItems() {
  return (
    <div className="overview-edit-items">
      <ImageFromAssets
        onClick={() => console.log("pen click")}
        name="overview-edit.png"
      />
      <ImageFromAssets
        onClick={() => console.log("trash click")}
        name="actions-trash.svg"
      />
      <ImageFromAssets
        onClick={() => console.log("three dots click")}
        name="overview-three-dots.png"
      />
    </div>
  );
}
