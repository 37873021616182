import "./FormInput.scss";
import {
  ChangeEvent,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  RefObject,
  useState,
} from "react";
import {ImageFromAssets} from "@/shared/ImageFromAssets/ImageFromAssets";

interface FormInputProps {
  name?: string;
  type?: string;
  maxLength?: number;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  value?: string | number;
  autoComplete?: string;
  autoSelect?: boolean;
  prefixIconName?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: MouseEventHandler;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  onKeyDown?: KeyboardEventHandler;
  onKeyUp?: KeyboardEventHandler;
  error?: ReactNode;
  accept?: string;
  innerRef?: RefObject<HTMLInputElement> | null;
  withFocus?: boolean;
  errorMessage?: string;

  password?: boolean;
}

export function FormInput({
                            name,
                            type,
                            maxLength = 200,
                            placeholder,
                            className,
                            disabled,
                            readOnly,
                            value,
                            autoComplete,
                            prefixIconName,
                            onChange,
                            onClick,
                            onFocus,
                            onBlur,
                            onKeyDown,
                            onKeyUp,
                            error,
                            errorMessage,
                            password,
                            accept = "image/*, .pdf",
                          }: FormInputProps): JSX.Element {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const showPassword = type !== "password" || isPasswordVisible;

  return (
    <div className="form-input">
      <input
        className={`form-input__input 
          ${(error || errorMessage) && "form-input__input--error"}
          ${disabled && "form-input__input--disabled"}
          ${className}
          `}
        type={!showPassword ? "password" : undefined}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        maxLength={maxLength}
        value={value}
        autoComplete={autoComplete}
        onChange={onChange}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        accept={accept}
      />

      {type === "password" && (
        <ImageFromAssets
          className="form-input__password-icon"
          onClick={() => setIsPasswordVisible((prev) => !prev)}
          name={isPasswordVisible ? "eye.svg" : "eye-off.svg"}
        />
      )}

      <div className="form-input__error-message">{errorMessage}</div>
    </div>
  );
}
