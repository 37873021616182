import {ModalContext} from "@/contexts";
import {Form} from "@/shared";
import {Modal} from "@/shared/Modal/Modal";
import {useContext} from "react";
import "./ClientInfoModal.scss";
import {IClient} from "@/interfaces/interface";

type Props = {
  client: IClient;
  getClients: () => Promise<void>;
};

export function ClientInfoModal({client}: Props) {
  const {closeModal} = useContext(ModalContext);

  const {
    name,
    contactInfo,
    billingInfo,
    pin,
    admin,
    numberOfAthletes,
    numberOfCoaches,
  } = client;
  const {email} = admin || {};

  return (
    <Modal className="client-info-modal">
      <Modal.Header text="Org Admin Info"/>

      <Form.Row className="client-info-modal__first-row">
        <div>
          <Form.Label>Coaches count</Form.Label>
          <Form.Input
            value={numberOfCoaches}
            name={"name"}
            maxLength={40}
            disabled
          />
        </div>

        <div>
          <Form.Label>Athletes count</Form.Label>
          <Form.Input
            value={numberOfAthletes}
            name={"name"}
            maxLength={40}
            disabled
          />
        </div>
      </Form.Row>

      <Form.Row>
        <Form.Label>Org Admin pin</Form.Label>
        <Form.Input value={pin} name={"name"} maxLength={40} disabled/>
      </Form.Row>

      <Form.Row>
        <Form.Label>Email address</Form.Label>
        <Form.Input value={email || ""} name={"name"} maxLength={40} disabled/>
      </Form.Row>

      <Form.Row>
        <Form.Label>Contact info</Form.Label>
        <Form.Input
          value={contactInfo || ""}
          name={"contactInfo"}
          disabled
          maxLength={50}
        />
      </Form.Row>

      <Form.Row>
        <Form.Label>Billing info</Form.Label>
        <Form.Input
          value={billingInfo || ""}
          name={"billingInfo"}
          maxLength={50}
          disabled
        />
      </Form.Row>

      <Modal.Footer>
        <Form.Button type="outline" onClick={closeModal}>
          CANCEL
        </Form.Button>
      </Modal.Footer>
    </Modal>
  );
}
