import {ICoach} from "@/interfaces/interface";
import {Form, ImageFromAssets} from "@/shared";
import {useContext} from "react";
import {CustomerContext} from "@/contexts";
import Api from "@/api/api";
import {getDateInLocal} from "@/helpers";

type Props = {
  coachesByStatus: ICoach[];
};

export function RequestBodyAutoApproved({coachesByStatus}: Props) {
  const {getCustomerData} = useContext(CustomerContext);

  const handleRemoveClick = async (teamId: string, email: string) => {
    const res = await Api.delete(
      `/v2/organization-admin/organizations/teams/${teamId}/auto-approve-emails?email=${email}`
    );
    if (res.status === 200) {
      await getCustomerData();
    }
  };

  return (
    <>
      {coachesByStatus.map((coach, index) => {
        const {timestamp, userData, username, teamId = ""} = coach;
        const {email = "", name = ""} = userData || {};

        return (
          <div key={index} className="request-body-new">
            <div className="name-column">
              <div>{name}</div>
              <div>{email}</div>
            </div>

            <div className="date-column">{getDateInLocal(timestamp)}</div>
            <div/>
            <div className="actions-column">
              <Form.Button
                onClick={() => handleRemoveClick(teamId, email)}
                className="decline-button"
              >
                <ImageFromAssets name="user-decline.svg"/>
              </Form.Button>
            </div>
          </div>
        );
      })}
    </>
  );
}
