import {AuthContext, CustomerContext, ModalContext} from "@/contexts";
import {ITeam, UserRoleEnum} from "@/interfaces/interface";
import {Form, ImageFromAssets, ItemContainer} from "@shared";
import {useContext, useEffect} from "react";
import "./TeamAthletes.scss";
import {AddAthleteModal} from "@/components";
import {AthleteTableItem} from "@/components/TableItems/AthleteTableItem/AthleteTableItem";
import {SelectAddAthleteModal} from "@/components/Modals/SelectAddAthleteModal/SelectAddAthleteModal";
import {SelectExistingAthleteModal} from "@/components/Modals/SelectExistingAthleteModal/SelectExistingAthleteModal";

type Props = {
  selectedTeam: ITeam;
};

export function TeamAthletes({selectedTeam}: Props) {
  const {openModal} = useContext(ModalContext);
  const {getCustomerData} = useContext(CustomerContext);
  const {userRole} = useContext(AuthContext);

  const {id, pin, approvedAthletes = []} = selectedTeam;

  const handleAddAthlete = () => {
    openModal(
      <SelectAddAthleteModal
        openAthleteCreateModal={handleOpenAddAthleteModal}
        openAthleteSelectModal={handleOpenSelectExistingAthleteModal}
      />
    )
  }

  const handleOpenAddAthleteModal = () => {
    openModal(<AddAthleteModal selectedOrganization={selectedTeam}/>);
  };

  const handleOpenSelectExistingAthleteModal = () => {
    openModal(<SelectExistingAthleteModal selectedTeam={selectedTeam}/>);
  }

  const coachesExist = !selectedTeam?.coaches?.length;

  const getEmptyText = () => {
    if (!selectedTeam?.id) {
      return "Select some team";
    }
    if (coachesExist) {
      return "Add Some Athlete";
    }
  };

  const areSomeAthleteExist = approvedAthletes.length > 0;

  useEffect(() => {
    if (userRole === UserRoleEnum.ROLE_ORGANIZATION_ADMIN) {
      getCustomerData();
    }
  }, []);

  return (
    <ItemContainer className={`organization-athletes`}>
      <ItemContainer.Header
        className="organization-athletes__header"
        leftSide={<div>Athletes</div>}
        rightSide={
          <Form.Button
            onClick={handleAddAthlete}
            hidden={!selectedTeam?.id}
          >
            <ImageFromAssets name="user-approve.svg"/>
            ADD ATHLETE
          </Form.Button>
        }
      />

      <div className="organization-athletes__body">
        {areSomeAthleteExist && (
          <div>
            {approvedAthletes.map((athlete, index) => (
              <AthleteTableItem
                key={athlete.username}
                athlete={athlete}
                teamId={id}
                teamPin={pin}
              />
            ))}
          </div>
        )}

        {!areSomeAthleteExist && (
          <div className="organization-athletes__body__empty">
            <ImageFromAssets name="empty-coaches.png" className='organization-athletes__placeholder-img'/>
            <span>{getEmptyText()}</span>
          </div>
        )}
      </div>
    </ItemContainer>
  );
}
