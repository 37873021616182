import {EditTeamModal, InfoTeamModal} from "@/components";
import {CustomerContext, ModalContext} from "@/contexts";
import {ITeam} from "@/interfaces/interface";
import {ImageFromAssets} from "@/shared";
import {useContext} from "react";
import "./TeamTableItem.scss";
import {downloadExcelFile} from "@/helpers/download-file";
import dayjs from "dayjs";
import {DeleteTeamModal} from "@/components/Modals/DeleteTeamModal/DeleteTeamModal";

type Props = {
  onClick: () => void;
  isSelected: boolean;
  team: ITeam;
};

export function TeamTableItem({
                                isSelected,
                                team,
                                onClick,
                              }: Props) {
  const {openModal} = useContext(ModalContext);

  const {getTeams} = useContext(CustomerContext);

  const {id, name, pin} = team;

  const handleEditTeam = () => {
    openModal(
      <EditTeamModal
        organization={team}
      />
    );
  };
  const handleOpenInfoTeam = () => {
    openModal(<InfoTeamModal organization={team}/>);
  };

  const handleDeleteTeam = async () => {
    openModal(<DeleteTeamModal team={team}/>)
  };

  const handleExportExcel = async () => {
    try {
      await downloadExcelFile(
        `/v2/coach/organizations/teams/${id}/athletes/reports/excel`,
        `Team: ${name} ${dayjs().format("lll")}.xlsx`
      );
      await getTeams();
    } catch (e) {
    }
  }

  return (
    <div
      onClick={onClick}
      className={`admin-team-table-item ${
        isSelected && "organization-table-item--active"
      }`}
    >
      <div className="name">{name}</div>
      <div className="pin">{pin}</div>

      <div className="actions">
        <ImageFromAssets
          onClick={handleOpenInfoTeam}
          name="actions-info.svg"
        />
        <ImageFromAssets
          onClick={handleEditTeam}
          name="actions-edit.svg"
        />
        <ImageFromAssets
          onClick={handleDeleteTeam}
          name="actions-trash.svg"
        />
        <ImageFromAssets onClick={handleExportExcel} name="actions-excel.svg"/>
      </div>
    </div>
  );
}
